<template>
  <div
    class="sidebar tw-h-screen tw-bg-white tw-hidden xl:tw-inline-block tw-z-10"
  >
    <div
      class="sidebar__container tw-fixed tw-h-screen tw-overflow-y-scroll tw-pb-16 tw-bg-white"
    >
      <div
        class="tw-bg-white tw-flex tw-justify-center tw-items-center tw-h-20"
      >
        <img
          src="@/assets/img/logo-icon.svg"
          class="tw-m-auto tw-cursor-pointer"
          alt="getEquity"
          @click="homePage"
        />
      </div>
      <nav class="sidebar__nav">
        <section class="sidebar__section tw-pt-8 tw-pb-5">
          <SidebarMenu title="Finish Setup" href="/setup" :focus="setupFocus" />
          <div class="tw-w-[170px] tw-mx-auto">
            <Skeletor
              v-for="a in 4"
              :key="a"
              height="7"
              class="tw-mt-8 sidebar__skeleton"
              :shimmer="true"
            />
          </div>
        </section>
      </nav>
    </div>
  </div>
</template>

<script>
  import SidebarMenu from "@/components/navigation/SidebarMenu.vue";
  export default {
    name: "SetupSidebar",

    components: { SidebarMenu },

    data() {
      return {
        setupFocus: false,
      };
    },

    created() {
      this.setFocus(this.$route.name);
    },

    methods: {
      setFocus(route) {
        this.setupFocus = false;
        switch (route) {
          case "Setup":
            this.setupFocus = true;
            break;
        }
      },
    },

    watch: {
      $route(to, from) {
        if (to !== from) {
          this.setFocus(this.$route.name);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar {
    width: $side-bar-width;

    &__container {
      width: $side-bar-width;

      .faq-btn {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    &__nav {
      border-radius: 6px;
    }
    &__skeleton {
      background-color: #e1e6f0;
    }
  }
</style>
