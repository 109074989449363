<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isVerified"
    :status="organisationDetails.kyc.identityDocument"
  >
    <form @submit.prevent="checkFormValidation">
      <div class="lg:tw-flex tw-flex-wrap tw-gap-x-5">
        <label
          for="document type"
          class="tw-text-[#323E67] tw-leading-[-150%] tw-tracking-[-0.12px] tw-text-sm tw-mb-2 tw-block tw-w-full"
          >Upload Identification Document</label
        >
        <div class="lg:tw-max-w-[513px] tw-w-full md:tw-w-2/3">
          <DragNDrop
            class="tw-mb-4"
            accept="image/png,image/jpeg,image/jpg"
            :validate="validateForm"
            :currentValue="idDocument.documents[0].url"
            :startUpload="uploadIdDoc"
            @re-upload="uploadIdDoc = false"
            @upload-complete="submitIdDocument"
            @file-added="setID"
            @is-valid="handleValidation"
            @upload-error="idUploadError"
          />
          <div
            class="tw-flex tw-flex-wrap tw-gap-y-3 tw-mb-8 lg:tw-gap-y-0 tw-gap-x-6 lg:tw-mb-[72px]"
          >
            <div
              class="tw-text-[#323E67] tw-text-sm tw-cursor-pointer tw-flex tw-items-center tw-gap-x-1"
              v-for="(doc, index) in docTypes"
              :key="index"
              @click="documentName = doc.name"
            >
              <input
                class="tw-border-primary"
                type="radio"
                name="Identification Document"
                :id="doc.value"
                :value="doc.name"
                v-model="documentName"
              />
              {{ doc.name }}
            </div>
            <p
              class="tw-text-red-error tw-text-left tw-w-full tw-mt-1"
              v-if="validateForm && !this.idDocument.documents[0].name"
            >
              Please select a type
            </p>
          </div>
        </div>
        <div class="lg:tw-max-w-[157px] tw-text-xs tw-text-[#8791A7] tw-mb-10">
          <p class="tw-mb-6">Your document must be clear and legible.</p>
          <p>File formats allowed: JPEG, PNG</p>
        </div>
        <div class="lg:tw-max-w-[513px] tw-mr-auto tw-w-full">
          <button
            class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white tw-block tw-py-4 tw-max-w-[158px] tw-ml-auto tw-text-sm"
            :disabled="iDLoading || loading"
          >
            <span v-if="!iDLoading || loading">Submit</span>
            <img
              v-show="iDLoading"
              class="tw-m-auto"
              src="@/assets/img/spinner.svg"
              alt="animated spinner"
            />
          </button>
        </div>
      </div>
    </form>
  </Accordion>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import Accordion from "@/components/setup/Accordion";
  import DragNDrop from "@/components/general/DragNDrop";
  export default {
    name: "identityDocument",
    data() {
      return {
        idDocument: {
          documents: [
            {
              name: "",
              url: "",
              type: "identification",
            },
          ],
        },
        info: {
          title: "Identification Document",
          description:
            "International passport, National ID card, Drivers licence, Voters card of key individual",
        },
        documentName: "",
        docTypes: [
          { name: "Passport", value: "IDENTIFICATION" },
          { name: "National ID card", value: "IDENTIFICATION" },
          { name: "Voter's Card", value: "IDENTIFICATION" },
        ],
        iDLoading: false,
        uploadIdDoc: false,
        validateForm: false,
        validationResult: [],
      };
    },
    components: {
      Accordion,
      DragNDrop,
    },
    watch: {
      documentName(val) {
        this.idDocument.documents[0].name = val;
        this.validationResult.push(true);
      },
      validationResult: {
        deep: true,
        handler(val) {
          if (val.length === 2 && !val.includes(false)) {
            this.uploadIdDocument();
          }
        },
      },
    },
    computed: {
      ...mapGetters(["loading"]),
      ...mapGetters("organisationModule", ["organisationDetails"]),
      isVerified() {
        return this.organisationDetails.kyc.identityDocument === "Approved";
      },
    },
    methods: {
      ...mapActions("organisationModule", ["submitKycDocument"]),
      ...mapActions(["showToast", "setLoading"]),
      setActiveTab(title) {
        this.$emit("is-active", title);
      },
      handleValidation(value) {
        this.validationResult.push(value);
      },
      checkFormValidation() {
        this.validationResult = [];
        this.validateForm = true;
        if (!this.idDocument.documents[0].name) {
          this.handleValidation(false);
        } else {
          this.handleValidation(true);
        }
        setTimeout(() => {
          this.validateForm = false;
        }, 1000);
      },
      uploadIdDocument() {
        this.uploadIdDoc = true;
        this.iDLoading = true;
      },
      setID(url) {
        this.uploadIdDoc = false;
        this.idDocument.documents[0].url = url;
      },

      async submitIdDocument(url) {
        this.idDocument.documents[0].url = url;
        const response = await this.submitKycDocument(this.idDocument);
        if (response) {
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
        }
        this.$emit("is-active", "Business Documents (Optional)");
        this.iDLoading = false;
        this.uploadIdDoc = false;
      },

      idUploadError() {
        this.iDLoading = false;
        this.uploadIdDoc = false;
        this.setLoading(false);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
