<template>
  <section
    class="md:tw-w-96 tw-w-11/12 tw-flex tw-flex-col tw-items-center tw-pb-8 signin__section tw-pt-24 lg:tw-pt-32 md:tw-pt-20"
  >
    <h1 class="tw-font-bold tw-mb-3 tw-text-2xl tw-text-secondary">
      Institution Account
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-text-center tw-mb-10">
      Please log in to your account
    </p>
    <SigninForm @signIn="signIn" />
    <div class="tw-flex">
      <router-link
        :to="{ name: 'RequestLink' }"
        class="tw-text-sm tw-text-primary tw-mt-6"
      >
        I forgot my password
      </router-link>
    </div>
  </section>
</template>

<script>
  import { mapActions } from "vuex";
  import SigninForm from "@/components/onboarding/forms/SigninForm";
  import responseHandler from "@/utils/responseHandler.js";
  import api from "@/services/auth";
  import { removeItem, setItem } from "@/utils/storage.js";

  export default {
    name: "SignIn",

    components: {
      SigninForm,
    },

    methods: {
      ...mapActions("loadingModule", ["signinSuccess", "signinLoading"]),
      ...mapActions("organisationModule", ["setOrganisationProfile"]),
      ...mapActions(["showToast", "logout"]),

      async signIn(payload) {
        this.signinLoading(true);
        try {
          const response = await api.loginOrganisation(payload);
          const { data, message, status } = response.data;
          if (status === "success") {
            this.signinSuccess(true);
            this.showToast({
              description: message,
              display: true,
              type: "success",
            });
            removeItem("payload");
            setItem("org_details", data);
            this.setOrganisationProfile(data);
            setItem("org_token", data.token);
            this.$router.push({ name: "OverviewDetails" });
            this.signinLoading(false);
          }
        } catch (error) {
          responseHandler.handleError(error);
          this.signinLoading(false);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .signin {
    &__section {
      max-width: 367px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
