<template>
  <form @submit.prevent="createPin" class="tw-w-full pin__form">
    <div ref="codeInput" class="tw-flex tw-justify-center tw-items-center">
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.firstDigit"
        :error="errorOne || {}"
        :position="1"
        @input="setDigit"
      />
      <div class="tw-mb-4">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.secondDigit"
        :error="errorTwo || {}"
        :position="2"
        @input="setDigit"
      />
      <div class="tw-mb-4">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.thirdDigit"
        :error="errorThree || {}"
        :position="3"
        @input="setDigit"
      />
      <div class="tw-mb-4">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.forthDigit"
        :error="errorFour || {}"
        :position="4"
        @input="setDigit"
      />
    </div>
    <p class="tw-text-sm tw-text-gray tw-text-center tw-mb-10 pin__text">
      Please make sure to put a pin you can easily remember.
    </p>
    <OnboardingBtn :loading="loading" title="Continue" />
  </form>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required, maxLength } from "@vuelidate/validators";
  import OnboardingCodeInput from "@/components/onboarding/OnboardingCodeInput";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn";
  import responseHandler from "@/utils/responseHandler.js";
  import { getItem } from "@/utils/storage.js";
  import api from "@/services/auth";

  export default {
    name: "ConfirmPinForm",

    components: {
      OnboardingCodeInput,
      OnboardingBtn,
    },

    data() {
      return {
        v$: useValidate(),
        loading: false,
        fields: [],
        fieldNum: 0,
        payload: { pin: "" },
        digit: {
          firstDigit: "",
          secondDigit: "",
          thirdDigit: "",
          forthDigit: "",
        },
        errorOne: {},
        errorTwo: {},
        errorThree: {},
        errorFour: {},
      };
    },

    validations() {
      return {
        digit: {
          firstDigit: { required, maxLength: maxLength(1) },
          secondDigit: { required, maxLength: maxLength(1) },
          thirdDigit: { required, maxLength: maxLength(1) },
          forthDigit: { required, maxLength: maxLength(1) },
        },
      };
    },

    computed: {
      ...mapState({
        pin: (state) => state.organisationModule.pin,
      }),
    },

    mounted() {
      this.fields = this.$refs.codeInput.querySelectorAll("input");
      this.fieldNum = this.fields.length;
      if (this.pin === 0) {
        this.$router.push("/onboarding/create-pin");
        this.showToast({
          description: "Please re-enter pin.",
          display: true,
          type: "info",
        });
        return;
      }
    },

    methods: {
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      ...mapActions(["showToast"]),

      async createPin() {
        if (this.pin !== this.payload.pin) {
          this.showToast({
            description: "Pin values are not the same.",
            display: true,
            type: "error",
          });
          return;
        }
        this.v$.$validate();
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          this.loading = true;
          const id = getItem("org_details")._id;
          try {
            const response = await api.createPin(this.payload);
            if (response) {
              const { message } = response.data;
              const profile = await this.getOrganisationProfile(id);
              if (profile) {
                this.$router.push({ name: "Setup" });
                this.showToast({
                  description: message,
                  display: true,
                  type: "success",
                });
              }
            }
            this.loading = false;
          } catch (error) {
            responseHandler.handleError(error);
            this.loading = false;
          }
        }
      },

      setDigit(payload) {
        const keyCode = payload.$event ? payload.$event.inputType : null;
        switch (payload.position) {
          case 1:
            this.digit.firstDigit = payload.digit;
            this.v$.digit.firstDigit.$touch();
            this.errorOne = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              return;
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 2:
            this.digit.secondDigit = payload.digit;
            this.v$.digit.secondDigit.$touch();
            this.errorTwo = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 3:
            this.digit.thirdDigit = payload.digit;
            this.v$.digit.thirdDigit.$touch();
            this.errorThree = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 4:
            this.digit.forthDigit = payload.digit;
            this.v$.digit.forthDigit.$touch();
            this.errorFour = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            }
            break;

          default:
            break;
        }
      },

      concatDigits() {
        this.payload.pin = this.digit.firstDigit
          .concat(this.digit.secondDigit)
          .concat(this.digit.thirdDigit)
          .concat(this.digit.forthDigit);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pin {
    &__form {
      max-width: 287px;
    }
    &__text {
      max-width: 195px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
