<template>
  <section class="welcome__section">
    <div class="welcome__div">
      <img
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1667563098/getequityV2/Group_35_spfcdv.png"
        class="tw-w-44 tw-h-44 tw-mb-8 tw-mx-auto tw-block"
      />
      <h1
        class="tw-font-bold tw-text-xl tw-text-secondary tw-mb-3 tw-text-center"
      >
        Welcome to GetEquity
      </h1>
      <p class="tw-text-black-light tw-text-center welcome__text">
        We’ll take it from here. While we are reviewing your application, you
        can explore the app and build a watchlist.
      </p>
      <router-link :to="{ name: 'CreatePin' }" class="welcome__link">
        <BtnComponent title="Continue" />
      </router-link>
    </div>
  </section>
</template>

<script>
  import Modal from "@/components/general/Modal";
  import BtnComponent from "@/components/general/BtnComponent";
  import { mapGetters } from "vuex";
  export default {
    name: "WelcomeModal",
    components: {
      Modal,
      BtnComponent,
    },
    created() {
      if (!this.organisationDetails || !this.organisationDetails?.verified) {
        this.$router.push({ name: "CodeVerification" });
      }
    },
    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
    },
  };
</script>

<style lang="scss" scoped>
  .welcome {
    &__div {
      width: calc(100vw - 100px);
      box-sizing: border-box;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 30px;
      @media (min-width: 768px) {
        max-width: 610px;
        padding: 50px 78px;
      }
    }
    &__text {
      max-width: 453px;
      margin: 0 auto;
    }
    &__link {
      max-width: 400px;
      margin-top: 56px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
</style>
