<template>
  <div
    id="reset-password"
    class="md:tw-w-96 tw-w-11/12 tw-flex tw-flex-col tw-items-center tw-pb-8"
  >
    <h1 class="tw-font-bold tw-mb-3 tw-text-2xl tw-text-secondary">
      Create new password
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-text-center tw-mb-12">
      Ensure to set a password you can remember
    </p>
    <ResetPasswordForm @submitData="submitData" />
    <div class="tw-flex tw-mt-6">
      <p class="tw-text-sm tw-text-gray">Request another link?</p>
      &nbsp;
      <router-link
        to="/onboarding/request-link"
        class="tw-text-sm tw-text-primary"
      >
        Request link
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import ResetPasswordForm from "@/components/onboarding/forms/ResetPasswordForm";
  import { clearStorage } from "@/utils/storage";

  export default {
    name: "ResetPassword",

    components: { ResetPasswordForm },

    beforeRouteEnter(to, from, next) {
      const { token } = to.query;
      if (!token) {
        clearStorage();
        return next({ name: "SignIn" });
      }
      next();
    },

    methods: {
      ...mapActions("organisationModule", ["resetPassword"]),
      ...mapActions("loadingModule", [
        "resetPasswordSuccess",
        "resetPasswordLoading",
      ]),
      ...mapActions(["showToast"]),

      async submitData(payload) {
        this.resetPasswordLoading(true);
        payload.token = this.$route.query.token;
        const response = await this.resetPassword(payload);
        if (response) {
          this.resetPasswordSuccess(true);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.$router.push("/onboarding/signin");
        }
        this.resetPasswordLoading(false);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
