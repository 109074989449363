<template>
  <form
    @submit.prevent="$emit('submit-configuration', subscription)"
    class="tw-mt-12 tw-w-full configuration__form"
  >
    <label
      for="carry"
      class="tw-block tw-mb-2 tw-flex tw-items-center tw-relative"
      >Carry Percentage (%)
      <Tooltip
        color="grey"
        text="Carry, short for 'carried interest,' represents the percentage of profits that are paid to the lead of a fund or SPV."
      />
    </label>
    <input
      type="number"
      name="carry"
      step=".01"
      v-model="subscription.carry_percentage"
      id="carry"
      class="tw-h-12 tw-rounded tw-w-full tw-pl-3 tw-border-[#DDE1E9]"
      :class="
        v$.subscription.carry_percentage.$dirty &&
        v$.subscription.carry_percentage.$error
          ? null
          : ' tw-mb-6'
      "
      placeholder="10"
      @blur="v$.subscription.carry_percentage.$touch()"
    />
    <p
      class="tw-mb-5 tw-mt-1 tw-text-sm required"
      v-if="
        v$.subscription.carry_percentage.$dirty &&
          v$.subscription.carry_percentage.$error
      "
    >
      {{ v$.subscription.carry_percentage.$errors[0].$message }}
    </p>
    <div
      class="tw-mb-12 configuration-form__div lg:tw-grid"
      v-if="subscriptionInfo"
    >
      <div class="configuration-form__div configuration-form__div--half">
        <label for="fee" class="tw-block tw-mb-2"
          >Monthly Membership Fee ($)</label
        >
        <input
          type="text"
          v-model="monthly"
          name="monthly"
          id="monthly"
          @keypress="isNumber($event)"
          class="tw-h-12 tw-rounded tw-w-full tw-pl-3 tw-border-[#DDE1E9]"
          :class="
            v$.subscription.monthly.$dirty && v$.subscription.monthly.$error
              ? null
              : ' tw-mb-6'
          "
          placeholder="500"
          @blur="v$.subscription.monthly.$touch()"
        />
        <p
          class="tw-mb-5 tw-mt-1 tw-text-sm required"
          v-if="
            v$.subscription.monthly.$dirty && v$.subscription.monthly.$error
          "
        >
          {{ v$.subscription.monthly.$errors[0].$message }}
        </p>
      </div>
      <!-- <div class="configuration-form__div configuration-form__div--half">
          <label for="fee" class="tw-block tw-mb-2"
            >Bi-Annual Membership Fee ($)</label
          >
          <input
            type="number"
            v-model="subscription.bi_annually"
            name="bi_annually"
            id="bi_annually"
            class="tw-h-12 tw-rounded tw-w-full tw-pl-3 tw-border-[#DDE1E9]"
            :class="
              v$.subscription.bi_annually.$dirty &&
              v$.subscription.bi_annually.$error
                ? null
                : ' tw-mb-6'
            "
            placeholder="500"
            @blur="v$.subscription.bi_annually.$touch()"
          />
          <p
            class="tw-mb-5 tw-mt-1 tw-text-sm required"
            v-if="
              v$.subscription.bi_annually.$dirty &&
                v$.subscription.bi_annually.$error
            "
          >
            {{ v$.subscription.bi_annually.$errors[0].$message }}
          </p>
        </div> -->
      <div class="configuration-form__div">
        <label for="fee" class="tw-block tw-mb-2"
          >Yearly Membership Fee ($)</label
        >
        <input
          type="text"
          v-model="yearly"
          name="annually"
          id="annually"
          @keypress="isNumber($event)"
          class="tw-h-12 tw-rounded tw-w-full tw-pl-3 tw-border-[#DDE1E9]"
          :class="
            v$.subscription.annually.$dirty && v$.subscription.annually.$error
              ? null
              : ' tw-mb-6'
          "
          placeholder="500"
          @blur="v$.subscription.annually.$touch()"
        />
        <p
          class="tw-mb-5 tw-mt-1 tw-text-sm required"
          v-if="
            v$.subscription.annually.$dirty && v$.subscription.annually.$error
          "
        >
          {{ v$.subscription.annually.$errors[0].$message }}
        </p>
      </div>
    </div>
    <div class="tw-mb-12 tw-max-w-[414px]">
      <label
        for="preview_deal"
        class="tw-flex tw-font-CircularStd-medium tw-font-medium tw-text-secondary tw-gap-x-4 tw-text-base tw-mb-2"
      >
        <ToggleButton
          @value-selected="setPreview"
          :value="subscription.preview_deal"
        />
        Allow new members preview deals
      </label>
      <p class="tw-text-sm tw-text-black-light">
        When turned on, newly invited members that are yet to pay their
        membership fees can preview deals. They are only prompted to subscribe
        when they try to invest.
      </p>
    </div>
    <div class="configuration__button tw-mr-auto">
      <CustomButton
        title="Save changes"
        :disabled="v$.$invalid"
        :loading="loading"
      />
    </div>
  </form>
</template>

<script>
  import useValidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import ToggleButton from "@/components/general/ToggleButton";
  import { mapGetters } from "vuex";
  import CustomButton from "@/components/general/BtnComponent";
  import { convertToLocale, isNumber } from "@/utils/helpers.js";
  import Tooltip from "@/components/general/Tooltip.vue";
  export default {
    name: "ConfigurationForm",
    data() {
      return {
        v$: useValidate(),
        monthly: 0,
        yearly: 0,
        subscription: {
          carry_percentage: "",
          monthly: "",
          annually: "",
          preview_deal: false,
        },
      };
    },
    validations() {
      return {
        subscription: {
          carry_percentage: {
            required,
          },
          monthly: {
            required,
          },
          annually: {
            required,
          },
        },
      };
    },
    components: {
      ToggleButton,
      CustomButton,
    },
    computed: {
      ...mapGetters(["loading"]),
      ...mapGetters("Account", ["subscriptionInfo"]),
      ...mapGetters("organisationModule", ["organisationDetails"]),
      monthlyFormatted() {
        let monthlyFormatted = null;
        if (this.monthly) {
          const monthly = Number(
            String(this.monthly)
              .split(",")
              .join("")
          );
          this.subscription.monthly = monthly;
          monthlyFormatted = this.convertToLocale(monthly);
        }
        return monthlyFormatted;
      },
      yearlyFormatted() {
        let yearlyFormatted = null;
        if (this.yearly) {
          const yearly = Number(
            String(this.yearly)
              .split(",")
              .join("")
          );
          this.subscription.annually = yearly;
          yearlyFormatted = this.convertToLocale(yearly);
        }
        return yearlyFormatted;
      },
    },
    methods: {
      convertToLocale,
      isNumber,
      setPreview(boolean) {
        this.subscription.preview_deal = boolean;
      },
    },
    mounted() {
      this.subscription.carry_percentage = this.organisationDetails.carry;
      this.monthly = convertToLocale(this.subscriptionInfo.monthly);
      this.yearly = convertToLocale(this.subscriptionInfo.annually);
      this.subscription.monthly = this.subscriptionInfo.monthly;
      this.subscription.preview_deal = this.subscriptionInfo.preview_deal;
      this.subscription.annually = this.subscriptionInfo.annually;
    },
    watch: {
      monthlyFormatted(val) {
        this.monthly = val;
      },
      yearlyFormatted(val) {
        this.yearly = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .configuration {
    @include fadeIn;
    height: 70vh;
    overflow-y: scroll;
    &__form {
      max-width: 420px;
      @media (min-width: 1024px) {
        max-width: 523px;
      }
    }
    &-form__div {
      &--half,
      &--small {
        flex-basis: 100%;
      }
    }
    &__button {
      background: #4d84ff;
      color: #fff;
      max-width: 209px;
      display: block;
      &:disabled {
        background: #c2d3ff;
      }
    }
    @media (min-width: 768px) {
      height: 100vh;
      &-form__div {
        column-gap: 24px;
        grid-template-columns: 48% 48%;
        &--half {
          flex-basis: 48%;
          /* flex-grow: 2; */
        }
        &--small {
          flex-basis: 35%;
          max-width: 156px;
        }
      }
    }
  }
</style>
