<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isVerified"
    :showButton="true"
    @button-click="showBankModal = true"
  >
    <Teleport to="#teleport-modal">
      <AddBankModal
        v-if="showBankModal"
        title="Add Bank Account"
        @close="showBankModal = false"
        @goBack="showBankModal = false"
        :bgChange="true"
      >
        <template v-slot:content>
          <div class="tw-px-4 lg:tw-px-10">
            <!-- <h2 class="tw-font-bold tw-text-lg tw-text-secondary tw-mt-7">
              Select type of account
            </h2>
            <p class="tw-text-[#4C5B90] tw-mt-2">
              GetEquity lets you link a Nigerian bank account or an
              international bank account.
            </p> -->
            <SelectCountryForm @bank-connected="proceed" />
          </div>
        </template>
      </AddBankModal>
    </Teleport>
  </Accordion>
</template>

<script>
  import Accordion from "@/components/setup/Accordion";
  import SelectCountryForm from "@/components/general/SelectCountryForm";
  import AddBankModal from "@/components/general/sidemodal/SideModal";
  import { mapActions, mapGetters } from "vuex";
  import responseHandler from "@/utils/responseHandler.js";
  import bankApi from "@/utils/mono";
  export default {
    name: "BankInfo",
    data() {
      return {
        info: {
          title: "Bank Account (Optional)",
          description: "Link your bank account for seamless transactions.",
        },
        showBankModal: false,
        activeTab: "",
      };
    },
    components: {
      Accordion,
      SelectCountryForm,
      AddBankModal,
    },
    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
      isVerified() {
        return this.organisationDetails.kyc.bank;
      },
    },
    methods: {
      ...mapActions(["showToast", "setLoading"]),
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      setActiveTab(title) {
        this.$emit("is-active", title);
      },
      async proceed() {
        this.showBankModal = false;
        await this.getOrganisationProfile();
        if (this.$route.name === "Setup") {
          this.$router.push({
            name: "DashboardView",
          });
        }
      },
      async handleStitchFinalResponse() {
        if (
          !this.$route.query.code ||
          (!this.$route.query.code && this.$route.query.state)
        ) {
          this.showToast({
            description: "We could not complete this integration.",
            display: true,
            type: "error",
          });
          this.$router.push({
            name: "Setup",
          });
        } else {
          const currentUrl = `${window.location.origin}/setup`;
          const data = {
            verifier: localStorage.getItem(this.$route.query.state),
            code: this.$route.query.code,
            redirectUri: currentUrl,
          };
          this.setLoading(true);
          try {
            await bankApi.finalizeBankConnection("stitch", data);
            this.showToast({
              description: "Bank connection was successful",
              display: true,
              type: "success",
            });
            if (this.$route.name === "Setup") {
              this.$router.push({
                name: "DashboardView",
              });
            }
            this.setLoading(false);
          } catch (error) {
            if (this.$route.name !== "IdentityVerification") {
              this.$router.push({
                name: "Setup",
              });
            }
            this.setLoading(false);
            responseHandler.handleError(error);
          }
        }
      },
      async handleNordigenFinalResponse() {
        if (this.$route.query.error) {
          this.showToast({
            description: "We could not complete this integration.",
            display: true,
            type: "error",
          });
          this.$router.push({
            name: "Setup",
          });
        } else {
          const data = {
            code: this.$route.query.ref,
          };
          this.setLoading(true);
          try {
            await bankApi.finalizeBankConnection("nordigen", data);
            this.setLoading(false);
            this.showToast({
              description: "Bank connection was successful",
              display: true,
              type: "success",
            });
            if (this.$route.name === "Setup") {
              this.$router.push({
                name: "DashboardView",
              });
            }
          } catch (error) {
            this.setLoading(false);
            responseHandler.handleError(error);
            this.$router.push({
              name: "Setup",
            });
          }
        }
      },
    },
    mounted() {
      if (
        (this.$route.query.scope && this.$route.query.state) ||
        this.$route.query.state
      ) {
        // If we're sent from stitch
        this.handleStitchFinalResponse();
      }
      if (this.$route.query.ref) {
        // If we're sent from nordigen
        this.handleNordigenFinalResponse();
      }
    },
  };
</script>

<style lang="scss" scoped></style>
