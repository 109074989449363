<template>
  <form @submit.prevent="enterPin" class="tw-w-full pin__form">
    <div ref="codeInput" class="tw-flex tw-justify-center tw-items-center">
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.firstDigit"
        :error="errorOne || {}"
        :position="1"
        @input="setDigit"
      />
      <div class="tw-mb-4">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.secondDigit"
        :error="errorTwo || {}"
        :position="2"
        @input="setDigit"
      />
      <div class="tw-mb-4">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.thirdDigit"
        :error="errorThree || {}"
        :position="3"
        @input="setDigit"
      />
      <div class="tw-mb-4">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="password"
        :digit="digit.forthDigit"
        :error="errorFour || {}"
        :position="4"
        @input="setDigit"
      />
    </div>
    <p class="tw-text-sm tw-text-gray tw-text-center tw-mb-10 pin__text">
      Please make sure to put a pin you can easily remember.
    </p>
    <OnboardingBtn title="Continue" />
  </form>
</template>

<script>
  import { mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required, maxLength } from "@vuelidate/validators";
  import OnboardingCodeInput from "@/components/onboarding/OnboardingCodeInput";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn";

  export default {
    name: "CreatePinForm",

    components: {
      OnboardingCodeInput,
      OnboardingBtn,
    },

    data() {
      return {
        v$: useValidate(),
        code: "",
        fields: [],
        fieldNum: 0,
        digit: {
          firstDigit: "",
          secondDigit: "",
          thirdDigit: "",
          forthDigit: "",
        },
        errorOne: {},
        errorTwo: {},
        errorThree: {},
        errorFour: {},
      };
    },

    validations() {
      return {
        digit: {
          firstDigit: { required, maxLength: maxLength(1) },
          secondDigit: { required, maxLength: maxLength(1) },
          thirdDigit: { required, maxLength: maxLength(1) },
          forthDigit: { required, maxLength: maxLength(1) },
        },
      };
    },

    mounted() {
      this.fields = this.$refs.codeInput.querySelectorAll("input");
      this.fieldNum = this.fields.length;
    },

    methods: {
      ...mapActions("organisationModule", ["setPin"]),

      enterPin() {
        this.setPin(this.code);
        this.$router.push("/onboarding/confirm-pin");
      },

      setDigit(payload) {
        const keyCode = payload.$event ? payload.$event.inputType : null;
        switch (payload.position) {
          case 1:
            this.digit.firstDigit = payload.digit;
            this.v$.digit.firstDigit.$touch();
            this.errorOne = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              return;
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 2:
            this.digit.secondDigit = payload.digit;
            this.v$.digit.secondDigit.$touch();
            this.errorTwo = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 3:
            this.digit.thirdDigit = payload.digit;
            this.v$.digit.thirdDigit.$touch();
            this.errorThree = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 4:
            this.digit.forthDigit = payload.digit;
            this.v$.digit.forthDigit.$touch();
            this.errorFour = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            }
            break;

          default:
            break;
        }
      },

      concatDigits() {
        this.code = this.digit.firstDigit
          .concat(this.digit.secondDigit)
          .concat(this.digit.thirdDigit)
          .concat(this.digit.forthDigit);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pin {
    &__form {
      max-width: 287px;
    }
    &__text {
      max-width: 195px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
