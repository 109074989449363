<template>
  <section
    class="homepage__section tw-h-full md:min-tw-h-screen lg:min-tw-h-screen tw-bg-gray-lightest tw-pb-32 md:tw-pb-0"
  >
    <header
      class="tw-h-20 tw-w-full tw-items-center tw-flex tw-justify-between tw-px-8 md:tw-px-[50px]"
    >
      <a
        href="http://getequity.io"
        target="_blank"
        rel="noopener noreferrer"
        :class="{ 'tw-mx-auto': organisationDetails }"
      >
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1654256869/getequityV2/GetEquity_Logo_1_w9teqh.svg"
          alt="GetEquity Logo"
        />
      </a>
      <button
        class="tw-bg-primary tw-leading-[140%] tw-font-normal tw-text-white tw-text-base tw-rounded tw-w-36 tw-block button"
      >
        <div class="tw-flex tw-gap-x-3 tw-items-center tw-justify-center">
          Sign In
          <img
            class="button__image"
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1656410547/getequityV2/Icons_ih8h4g.svg"
          />
        </div>
        <nav class="button__nav tw-absolute">
          <div
            class="tw-h-14 tw-py-4 tw-px-5 tw-flex tw-justify-center tw-border-b tw-border-gray-light"
          >
            <a
              :href="
                windowLocation.origin.includes('getequity.io')
                  ? 'https://app.getequity.io/onboarding/signin'
                  : 'https://staging--getequityinvestors.netlify.app/onboarding/signin'
              "
              target="_blank"
              class="tw-text-sm tw-font-medium tw-text-secondary"
              rel="noopener noreferrer"
              >Sign in as an Investor</a
            >
          </div>
          <div
            class="tw-h-[56px] tw-py-4 tw-px-5 tw-flex tw-justify-center tw-border-b tw-border-[#f0f4fd]"
          >
            <a
              class="tw-text-sm tw-font-medium tw-text-secondary"
              :href="
                windowLocation.origin.includes('getequity.io')
                  ? 'https://founders.getequity.io/onboarding/account-type'
                  : 'https://staging--company-dealroom.netlify.app/onboarding/account-type'
              "
              target="_blank"
              >Sign in as a Founder</a
            >
          </div>
          <div class="tw-h-[56px] tw-py-4 tw-px-5 tw-flex tw-justify-center">
            <router-link
              class="tw-text-sm tw-font-medium tw-text-secondary"
              :to="{ name: 'SignIn' }"
              >Sign in as an Institution</router-link
            >
          </div>
        </nav>
      </button>
    </header>
    <div class="homepage__div tw-pb-8 tw-px-4">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-20 lg:tw-mt-32 tw-mb-12"
      >
        <h3 class="tw-text-3xl tw-font-bold">Get Started</h3>
        <p class="tw-text-gray-lightest3 tw-mt-2">
          How would you like to use GetEquity?
        </p>
      </div>
      <div
        class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-x-2 xl:tw-gap-12 homepage-account-types__div"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="tw-bg-white tw-cursor-pointer tw-rounded-lg tw-border tw-border-white hover:tw-border-primary tw-px-6 lg:tw-px-4 xl:tw-px-6 tw-py-8 tw-mb-8 lg:tw-mb-0 md:tw-mx-40 lg:tw-mx-0"
          @click="gotoPage(option.link)"
        >
          <h3
            class="tw-text-xl tw-font-bold tw-flex tw-items-center tw-justify-between tw-relative"
          >
            {{ option.header }}
            <Tooltip
              color="grey"
              :text="getTypeTooltipText(option.header)"
              :isRelative="true"
            />
          </h3>
          <p class="tw-text-gray-lightest3 tw-mt-2">{{ option.subHeader }}</p>
        </div>
      </div>
      <div
        class="tw-w-full tw-max-w-md tw-mx-auto tw-flex tw-justify-center tw-bg-orange-lightest tw-rounded tw-cursor-pointer tw-border tw-border-orange-lightest hover:tw-border-primary tw-py-2 lg:tw-mt-32"
        @click="showInfoModal = !showInfoModal"
      >
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1661781045/getequityV2/info-orange_o9aegn.svg"
          alt="question mark"
        />
        <p class="tw-text-orange tw-text-sm tw-ml-2">
          Learn about GetEquity account types
        </p>
      </div>
    </div>
    <InfoModal v-if="showInfoModal" @close="showInfoModal = !showInfoModal">
      <template v-slot:content>
        <div>
          <img
            src="@/assets/img/info.svg"
            class="tw-mx-auto tw-cursor-pointer"
            alt="info"
          />
          <h3 class="tw-font-semibold tw-text-center tw-mt-4 tw-mb-2">
            GetEquity Account Types
          </h3>
          <p class="tw-text-sm tw-text-black-light tw-text-center">
            GetEquity caters to different user types: Investors, Founders and
            Institutions.
          </p>
          <div class="tw-my-8">
            <div class="tw-flex tw-items-start tw-mb-6">
              <img
                src="https://res.cloudinary.com/djalafcj9/image/upload/v1653916132/getequityV2/Vector_si2wy8.svg"
                class="tw-mx-auto tw-cursor-pointer"
                alt="info"
              />
              <div class="tw-ml-2">
                <h3 class="tw-font-bold">Investors</h3>
                <p class="tw-text-sm tw-text-black-light tw-mt-1">
                  Own a share of companies listed on GetEquity with as low as
                  $10.
                </p>
              </div>
            </div>
            <div class="tw-flex tw-items-start tw-mb-6">
              <img
                src="https://res.cloudinary.com/djalafcj9/image/upload/v1653916132/getequityV2/Vector_si2wy8.svg"
                class="tw-mx-auto tw-cursor-pointer"
                alt="info"
              />
              <div class="tw-ml-2">
                <h3 class="tw-font-bold">Founders</h3>
                <p class="tw-text-sm tw-text-black-light tw-mt-1">
                  Apply to get listed on GetEquity and start raising funds
                  publicly or privately.
                </p>
              </div>
            </div>
            <div class="tw-flex tw-items-start tw-mb-6">
              <img
                src="https://res.cloudinary.com/djalafcj9/image/upload/v1653916132/getequityV2/Vector_si2wy8.svg"
                class="tw-mx-auto tw-cursor-pointer"
                alt="info"
              />
              <div class="tw-ml-2">
                <h3 class="tw-font-bold">Institution</h3>
                <p class="tw-text-sm tw-text-black-light tw-mt-1">
                  Create special purpose vehicles to invest in deals exclusively
                  with your peers.
                </p>
              </div>
            </div>
          </div>
          <Btn title="I Understand" @click="showInfoModal = !showInfoModal" />
        </div>
      </template>
    </InfoModal>
  </section>
</template>

<script>
  import InfoModal from "@/components/general/SmallModal.vue";
  import Btn from "@/components/general/BtnComponent.vue";
  import { getItem } from "@/utils/storage.js";
  export default {
    name: "LandingView",

    components: { InfoModal, Btn },
    computed: {
      organisationDetails() {
        const org = getItem("org_details");
        return org;
      },
      windowLocation() {
        return window.location;
      },
    },

    data() {
      return {
        showInfoModal: false,
        options: [
          {
            header: "As an Investor",
            subHeader:
              "Select if you are looking to invest in promising startups.",
            link: "https://app.getequity.io/onboarding/signin",
          },
          {
            header: "As a Founder",
            subHeader:
              "Select if you are looking to raise capital on GetEquity.",
            link: "https://founders.getequity.io/onboarding/account-type",
          },
          {
            header: "As an Institution",
            subHeader:
              "Select if you are looking to create syndicates or VCs to invest with peers.",
            link: "https://dealroom.getequity.io/onboarding/data-collection",
          },
        ],
      };
    },

    methods: {
      gotoPage(link) {
        switch (process.env.VUE_APP_ENV) {
          case "local":
            window.location =
              window.location.origin + "/onboarding/data-collection";

            break;
          case "staging":
            this.gotoStagingPage(link);
            break;
          case "institution-dev":
            this.gotoStagingPage(link);
            break;
          case "production":
            window.location = link;
            break;

          default:
            break;
        }
      },

      getTypeTooltipText(type) {
        let tooltip = null;
        switch (type) {
          case "As an Investor":
            tooltip =
              "Own a share of companies listed on GetEquity with as low as $10.";
            break;
          case "As a Founder":
            tooltip =
              "Apply to get listed on GetEquity and start raising funds publicly or privately.";
            break;
          case "As an Institution":
            tooltip =
              "Create special purpose vehicles to invest in deals exclusively with your peers.";
            break;
        }
        return tooltip;
      },

      gotoStagingPage(link) {
        if (
          window.location.origin.includes("staging") ||
          window.location.origin.includes("institution-dev")
        ) {
          switch (link) {
            case "https://app.getequity.io/onboarding/signin":
              window.location =
                "https://staging--getequityinvestors.netlify.app/onboarding/signin";
              break;
            case "https://founders.getequity.io/onboarding/account-type":
              window.location =
                "https://staging--company-dealroom.netlify.app/onboarding/account-type";
              break;
            case "https://dealroom.getequity.io/onboarding/data-collection":
              window.location =
                "https://staging--dealroom.netlify.app/onboarding/data-collection";
              break;

            default:
              break;
          }
          return;
        }

        if (window.location.origin.includes("dev")) {
          switch (link) {
            case "https://app.getequity.io/onboarding/signin":
              window.location =
                "https://dev--getequityinvestors.netlify.app/onboarding/signin";
              break;
            case "https://founders.getequity.io/onboarding/account-type":
              window.location =
                "https://dev--company-dealroom.netlify.app/onboarding/account-type";
              break;
            case "https://dealroom.getequity.io":
              window.location =
                "https://dev--dealroom.netlify.app/onboarding/data-collection";
              break;

            default:
              break;
          }
          return;
        }
      },
      gotoDevPage(link) {
        if (window.location.origin.includes("dev")) {
          switch (link) {
            case "https://app.getequity.io/onboarding/signin":
              window.location =
                "https://dev--getequityinvestors.netlify.app/onboarding/signin";
              break;
            case "https://founders.getequity.io/onboarding/account-type":
              window.location =
                "https://dev--company-dealroom.netlify.app/onboarding/account-type";
              break;
            case "https://dealroom.getequity.io/onboarding/data-collection":
              window.location =
                "https://institutional-merge-dev--dealroom.netlify.app/onboarding/data-collection";
              break;

            default:
              break;
          }
          return;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .homepage {
    &__section {
      @include fadeIn;
      @media (min-width: 1024px) {
        height: 100vh;
      }
    }

    &__div {
      @media (min-width: 1280px) {
        max-width: 1270px;
        margin-left: auto;
        margin-right: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &-account-types__div {
      max-width: 1040px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .button {
    transition: transform 1s ease-in-out;
    overflow: hidden;
    height: 45px;
    &:hover {
      position: relative;
      overflow: visible;
      .button__image {
        transition: transform 1s ease-in-out;
        transform: rotate(180deg) translateY(-2px);
      }
      .button__nav {
        bottom: 10px;
        top: 60px;
        opacity: 1;
        z-index: 10;
        right: 0;
      }
    }
    &__nav {
      top: -160px;
      right: 0;
      opacity: 0;
      background-color: #fff;
      border: 1px solid #e1e6f0;
      transition: all 800ms ease-in-out;
      box-shadow: 0px 2px 25px rgba(11, 40, 105, 0.15);
      border-radius: 4px;
      width: 200px;
      height: 166px;
    }
  }
</style>
