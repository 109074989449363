<template>
  <div class="tw-mb-10 tw-text-gray tw-text-sm md:tw-text-lg">
    <p class="tw-text-center">{{ subHeaderText }}</p>
  </div>
</template>

<script>
  export default {
    name: "OnboardingSubHeader",

    props: {
      subHeaderText: { type: String, default: () => "", required: true },
    },
  };
</script>

<style lang="scss" scoped></style>
