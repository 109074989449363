<template>
  <Accordion :info="info" @is-active="setActiveTab" :verified="isFilled">
    <ConfigurationForm
      v-if="subscriptionInfo"
      @submit-configuration="updateConfiguration"
    />
  </Accordion>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import Accordion from "@/components/setup/Accordion";
  import ConfigurationForm from "../account/configuration/ConfigurationForm";
  export default {
    name: "Configuration",
    data() {
      return {
        info: {
          title: "Configuration Settings",
          description:
            "Manage your membership fee e.g carry percentage, membership fee",
        },
        subscription: {
          carry_percentage: null,
          monthly: null,
          annually: null,
          preview_deal: false,
        },
      };
    },
    components: {
      Accordion,
      ConfigurationForm,
    },
    computed: {
      ...mapGetters("Account", ["subscriptionInfo"]),
      isActiveTab() {
        return this.activeTab === this.info.title;
      },
      isFilled() {
        return !Object.values(this.subscription).includes(null);
      },
    },
    emits: ["is-active"],
    created() {
      this.getSubscriptions();
    },
    methods: {
      ...mapActions("Account", ["fetchSubscriptions", "updateSubscription"]),
      ...mapActions(["showToast", "setLoading"]),
      setActiveTab(title) {
        this.$emit("is-active", title);
      },
      async getSubscriptions() {
        try {
          await this.fetchSubscriptions();
          this.subscription.carry_percentage = this.subscriptionInfo.carry;
          this.subscription.monthly = this.subscriptionInfo.monthly;
          this.subscription.preview_deal = this.subscriptionInfo.preview_deal;
          this.subscription.annually = this.subscriptionInfo.annually;
        } catch (error) {
          return error;
        }
      },

      async updateConfiguration(subscription) {
        this.setLoading(true);
        try {
          const response = await this.updateSubscription(subscription);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          await this.getSubscriptions();
          this.setLoading(false);
          this.$emit("is-active", "Identification Document");
        } catch (error) {
          this.setLoading(false);
          return error;
        }
      },
    },
  };
</script>

<style></style>
