<template>
  <div
    class="tw-relative xs:tw-w-28 tw-mb-6 xs:tw-mb-0 tw-flex tw-flex-col tw-bg-white"
  >
    <label :for="label"></label>
    <select
      class="tw-text-sm tw-rounded tw-p-4 xs:tw-rounded-br-none xs:tw-border-r-0 xs:tw-rounded-tr-none tw-cursor-pointer focus:tw-border-primary"
      :class="{
        [border]: border,
      }"
      name="country code"
      id="country-code"
      v-model="code"
    >
      <option
        class="tw-flex tw-items-center"
        v-for="(country, index) in countryCode.countries"
        :value="country.dial_code"
        :key="index"
      >
        <p>
          {{ country.flag }} &nbsp;{{
            country.dial_code
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
            country.name
          }}
        </p>
      </option>
    </select>
    <img
      src="@/assets/img/solid-chevron-down.svg"
      class="tw-absolute tw-right-2 tw-bottom-6 tw--z-10"
      alt="chevron icon"
    />
  </div>
</template>

<script>
  import countryCode from "@/utils/json/countryCode.json";

  export default {
    name: "OnboardingCountryCodeInput",

    emits: ["set"],

    props: {
      label: {
        type: String,
        default: () => "",
        required: true,
      },
      //border color class for the input
      border: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        countryCode,
        code: "+234",
      };
    },

    mounted() {
      this.emitCode(this.code);
    },

    methods: {
      emitCode(code) {
        this.$emit("set", code);
      },
    },

    watch: {
      code(newValue, oldValue) {
        deep: true;
        immediate: true;
        if (newValue !== oldValue) {
          this.emitCode(newValue);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
