<template>
  <form @submit.prevent="verifyCode" class="tw-w-full">
    <div ref="codeInput" class="tw-flex tw-justify-center tw-items-center">
      <OnboardingCodeInput
        label="Verification code"
        type="text"
        :digit="digit.firstDigit"
        :error="errorOne || {}"
        :position="1"
        :disabled="loading"
        @input="setDigit"
        @handlePaste="handlePaste"
      />
      <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="text"
        :digit="digit.secondDigit"
        :error="errorTwo || {}"
        :position="2"
        :disabled="loading"
        @input="setDigit"
        @handlePaste="handlePaste"
      />
      <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="text"
        :digit="digit.thirdDigit"
        :error="errorThree || {}"
        :position="3"
        :disabled="loading"
        @input="setDigit"
        @handlePaste="handlePaste"
      />
      <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="text"
        :digit="digit.forthDigit"
        :error="errorFour || {}"
        :position="4"
        :disabled="loading"
        @input="setDigit"
        @handlePaste="handlePaste"
      />
      <div class="tw-mb-6">&nbsp;&#8212;&nbsp;</div>
      <OnboardingCodeInput
        label="Verification code"
        type="text"
        :digit="digit.fifthDigit"
        :error="errorFive || {}"
        :position="5"
        :disabled="loading"
        @input="setDigit"
        @handlePaste="handlePaste"
      />
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-center tw-w-full tw-mt-14 tw-mb-8"
    >
      <!-- <p class="tw-text-sm tw-text-gray">Didn’t receive any code?</p> -->
      &nbsp;<button
        type="button"
        class="tw-text-sm tw-text-primary hover:tw-bg-transparent hover:tw-text-primary"
        @click="$emit('resend-code')"
      >
        Resend code
      </button>
      &nbsp;
      <!-- <p class="tw-text-sm tw-text-gray">or</p>
      &nbsp;
      <router-link
        to="/onboarding/data-collection"
        class="tw-text-sm tw-text-primary"
      >
        change details
      </router-link> -->
    </div>
    <OnboardingBtn :loading="loading" title="Continue" />
  </form>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required, maxLength } from "@vuelidate/validators";
  import OnboardingCodeInput from "@/components/onboarding/OnboardingCodeInput";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn";

  export default {
    name: "CodeVerificationForm",

    components: {
      OnboardingCodeInput,
      OnboardingBtn,
    },

    emits: ["verify", "resend-code"],

    data() {
      return {
        v$: useValidate(),
        code: "",
        fields: [],
        fieldNum: 0,
        digit: {
          firstDigit: "",
          secondDigit: "",
          thirdDigit: "",
          forthDigit: "",
          fifthDigit: "",
        },
        errorOne: {},
        errorTwo: {},
        errorThree: {},
        errorFour: {},
        errorFive: {},
      };
    },

    validations() {
      return {
        digit: {
          firstDigit: { required, maxLength: maxLength(1) },
          secondDigit: { required, maxLength: maxLength(1) },
          thirdDigit: { required, maxLength: maxLength(1) },
          forthDigit: { required, maxLength: maxLength(1) },
          fifthDigit: { required, maxLength: maxLength(1) },
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.loadingModule.verifyLoading,
        success: (state) => state.loadingModule.verifySuccess,
      }),
    },

    mounted() {
      this.fields = this.$refs.codeInput.querySelectorAll("input");
      this.fieldNum = this.fields.length;
    },

    methods: {
      ...mapActions("loadingModule", ["verifySuccess"]),

      handlePaste(value) {
        this.code = "";
        for (let index = 0; index < this.fieldNum; index++) {
          const payload = { digit: value[index], position: index + 1 };
          this.setDigit(payload);
        }
      },

      verifyCode() {
        this.v$.$validate();
        const hasValue = Object.keys(this.digit).every(
          (key) => this.digit[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          this.$emit("verify", this.code);
        } else {
          this.v$.$errors.map((error) => {
            if (error.$property === "firstDigit") this.errorOne = error;
            if (error.$property === "secondDigit") this.errorTwo = error;
            if (error.$property === "thirdDigit") this.errorThree = error;
            if (error.$property === "forthDigit") this.errorFour = error;
            if (error.$property === "fifthDigit") this.errorFive = error;
          });
        }
      },

      setDigit(payload) {
        const keyCode = payload.$event ? payload.$event.inputType : null;
        switch (payload.position) {
          case 1:
            this.digit.firstDigit = payload.digit;
            this.v$.digit.firstDigit.$touch();
            this.errorOne = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              return;
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 2:
            this.digit.secondDigit = payload.digit;
            this.v$.digit.secondDigit.$touch();
            this.errorTwo = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 3:
            this.digit.thirdDigit = payload.digit;
            this.v$.digit.thirdDigit.$touch();
            this.errorThree = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 4:
            this.digit.forthDigit = payload.digit;
            this.v$.digit.forthDigit.$touch();
            this.errorFour = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            } else {
              this.fields[payload.position].focus();
            }
            break;
          case 5:
            this.digit.fifthDigit = payload.digit;
            this.v$.digit.fifthDigit.$touch();
            this.errorFive = this.v$.$errors[0];
            this.concatDigits();
            if (keyCode === "deleteContentBackward") {
              this.fields[payload.position - 2].focus();
              this.fields[payload.position - 2].select();
            }
            break;

          default:
            break;
        }
      },

      concatDigits() {
        this.code = this.digit.firstDigit
          .concat(this.digit.secondDigit)
          .concat(this.digit.thirdDigit)
          .concat(this.digit.forthDigit)
          .concat(this.digit.fifthDigit);
      },
    },

    watch: {
      success(newValue) {
        deep: true;
        immediate: true;
        if (newValue === true) {
          this.digit = {
            firstDigit: "",
            secondDigit: "",
            thirdDigit: "",
            forthDigit: "",
            fifthDigit: "",
          };
          this.verifySuccess(false);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
