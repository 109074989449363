<template>
  <div id="mobile-sidebar" class="mobile-sidebar tw-fixed xl:tw-hidden tw-z-30">
    <nav class="navbar">
      <div class="navbar-container">
        <input
          class="tw-fixed tw-top-8 tw-left-5 tw-opacity-0 tw-w-7 tw-h-5 tw-cursor-pointer tw-z-40"
          type="checkbox"
          name="mobileSidebar"
          id="mobile-sidebar"
          ref="mobileSidebar"
        />
        <div
          class="hamburger-lines tw-fixed tw-top-8 tw-left-5 tw-flex tw-flex-col tw-w-7 tw-h-5 tw-justify-between tw-z-20"
        >
          <span class="line1 tw-h-1 tw-rounded tw-bg-gray"></span>
          <span class="line2 tw-h-1 tw-rounded tw-bg-gray"></span>
          <span class="line3 tw-h-1 tw-rounded tw-bg-gray"></span>
        </div>
        <div
          class="menu-items tw-absolute tw-h-screen tw-w-80 tw-bg-white tw-pt-16 tw-overflow-scroll tw-z-10"
        >
          <img
            src="@/assets/img/logo-icon.svg"
            class="tw-absolute tw-top-4 tw-right-10 tw-mb-6 tw-cursor-pointer"
            alt="getEquity-logo"
            @click="homePage"
          />
          <nav class="mobile-sidebar__nav">
            <section
              class="sidebar__section tw-pt-8 tw-pb-5 tw-border-black-light3 tw-border-b"
            >
              <SidebarMenu
                title="Finish Setup"
                href="/setup"
                @click="closeSidebar"
              />
              <div class="tw-w-[250px] tw-mx-auto">
                <Skeletor
                  v-for="a in 4"
                  :key="a"
                  height="7"
                  class="tw-mt-8 mobile-sidebar__skeleton"
                  :shimmer="true"
                />
              </div>
            </section>
          </nav>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  import SidebarMenu from "@/components/navigation/SidebarMenu.vue";
  import { getItem } from "@/utils/storage";

  export default {
    name: "MobileSetupSideBar",

    components: { SidebarMenu },
    computed: {
      organisationDetails() {
        const orgInfo = getItem("org_details");
        return orgInfo;
      },
      products() {
        const orgInfo = getItem("org_details");
        const products = orgInfo?.products;
        return products;
      },
    },

    methods: {
      homePage() {
        this.$router.push("/");
      },
      isSubscribedToProducts(product) {
        // API_KEY | PRIVATE_RAISE | INVEST | ESOP | PUBLIC_RAISE
        return this.products && this.products.includes(product);
      },

      faqPage() {
        this.$router.push("/faqs");
      },

      closeSidebar() {
        this.$refs.mobileSidebar.checked = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #mobile-sidebar {
    @media (max-width: 1280px) {
      .navbar-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
      }

      .navbar-container .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
      }

      .navbar-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
      }

      .navbar .menu-items {
        transform: translate(-150%);
        transition: transform 0.5s ease-in-out;
      }

      .navbar .menu-items .faq-btn {
        left: 50%;
        transform: translate(-50%, 0);
      }

      .navbar-container input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
      }

      .navbar-container
        input[type="checkbox"]:checked
        ~ .hamburger-lines
        .line1 {
        transform: rotate(38deg);
      }

      .navbar-container
        input[type="checkbox"]:checked
        ~ .hamburger-lines
        .line2 {
        transform: scaleY(0);
      }

      .navbar-container
        input[type="checkbox"]:checked
        ~ .hamburger-lines
        .line3 {
        transform: rotate(-38deg);
      }
    }
    .mobile-sidebar {
      &__skeleton {
        background-color: #e1e6f0;
      }
    }
  }
</style>
