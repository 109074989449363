<template>
  <form @submit.prevent="checkFormValidation" class="tw-w-full">
    <div class="tw-mt-4">
      <InputField
        type="email"
        name="email"
        @input-changed="storeValue"
        @is-valid="handleValidation"
        label="Enter email address"
        border="tw-border-[#DDE1E9]"
        :validate="validateForm"
      />
    </div>
    <OnboardingBtn :loading="loading" title="Continue" />
  </form>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import navigateTo from "@/mixins/navigateTo";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn";
  import InputField from "@/components/general/InputField";

  export default {
    name: "RequestLinkForm",

    components: {
      InputField,
      OnboardingBtn,
    },

    mixins: [navigateTo],

    emits: ["submit-data"],

    data() {
      return {
        payload: {
          email: "",
        },
        validateForm: false,
        validationResult: [],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.loadingModule.requestLinkLoading,
        success: (state) => state.loadingModule.requestLinkSuccess,
      }),
    },
    watch: {
      success(newValue) {
        if (newValue === true) {
          this.payload = {
            email: "",
          };
          this.requestLinkSuccess(false);
        }
      },
      validationResult: {
        deep: true,
        handler(val) {
          if (
            !val.includes(false) &&
            Object.keys(this.payload).length === val.length
          ) {
            this.submitData();
          }
        },
      },
      validateForm(val) {
        if (val) {
          setTimeout(() => {
            this.validateForm = false;
          }, 1000);
        }
      },
    },

    methods: {
      ...mapActions("loadingModule", ["requestLinkSuccess"]),
      storeValue({ name, value }) {
        this.payload[name] = value;
      },
      handleValidation(val) {
        this.validationResult.push(val);
      },

      checkFormValidation() {
        this.validateForm = true;
        this.validationResult = [];
      },

      submitData() {
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (hasValue) {
          this.$emit("submit-data", this.payload);
        }
      },

      setEmail(email) {
        this.payload.email = email;
      },

      validateMethod(field) {
        switch (field) {
          case "Enter email address":
            this.v$.$reset();
            this.v$.payload.email.$touch();
            if (this.v$.$errors.length === 0) return (this.emailError = {});
            this.emailError = this.v$.$errors[0];
            break;

          default:
            break;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
