<template>
  <div
    class=" tw-text-xl md:tw-text-3xl tw-leading-[42px] tw-font-bold tw-mb-1 md:tw-mb-3 tw-text-[#020B27]"
  >
    <h3 class="tw-text-center">{{ headerText }}</h3>
  </div>
</template>

<script>
  export default {
    name: "OnboardingHeader",

    props: {
      headerText: { type: String, default: () => "", required: true },
    },
  };
</script>

<style lang="scss" scoped></style>
