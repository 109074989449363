<template>
  <div
    id="data-collection"
    class="md:tw-w-96 tw-w-11/12 tw-flex tw-flex-col tw-items-center tw-pb-8"
  >
    <OnboardingHeader headerText="Create a deal portal" />
    <OnboardingSubHeader
      subHeaderText="Manage your deals, fund raises and investors with ease."
    />
    <DataCollectionForm @submitData="submitData" class="tw-w-full" />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import OnboardingHeader from "@/components/onboarding/OnboardingHeader";
  import OnboardingSubHeader from "@/components/onboarding/OnboardingSubHeader";
  import DataCollectionForm from "@/components/onboarding/forms/DataCollectionForm";
  import { removeItem, setItem, clearStorage } from "@/utils/storage.js";
  import responseHandler from "@/utils/responseHandler.js";
  import api from "@/services/auth";

  export default {
    name: "DataCollection",

    components: { OnboardingHeader, OnboardingSubHeader, DataCollectionForm },

    data() {
      return {
        type: "",
        headerText: "",
        subHeaderText: "",
      };
    },

    created() {
      this.type = "Syndicate";
      this.setHeader();
      this.setSubHeader();
    },

    methods: {
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      ...mapActions("loadingModule", ["createSuccess", "createLoading"]),
      ...mapActions(["showToast"]),

      async submitData(payload) {
        this.createLoading(true);
        try {
          const createResponse = await api.createOrganisation(payload);
          const { data, message, status } = createResponse.data;
          if (status === "success") {
            this.createSuccess(true);
            this.showToast({
              description: message,
              display: true,
              type: "success",
            });
            removeItem("payload");
            setItem("org_token", data.token);
            this.fetchOrgInfo(data._id);
          }
        } catch (error) {
          // console.log({ error });
          responseHandler.handleError(error);
          this.createLoading(false);
          clearStorage();
          this.$router.push({ name: "SignIn" });
        }
      },
      async fetchOrgInfo(id) {
        try {
          const response = await this.getOrganisationProfile(id);
          if (response) {
            this.$router.push({ name: "CodeVerification" });
          } else {
            clearStorage();
            this.$router.push({ name: "SignIn" });
          }
          this.createLoading(false);
        } catch (error) {
          responseHandler.handleError(error);
          this.createLoading(false);
          clearStorage();
          this.$router.push({ name: "SignIn" });
        }
      },

      setSubHeader() {
        let accountType = "";
        switch (this.type) {
          case "Syndicate":
            accountType = "a structured";
            break;

          case "Unstructured Syndicate":
            accountType = "an unstructured";
            break;

          case "Community":
            accountType = "a community";
            break;

          default:
            break;
        }
        this.subHeaderText = `You are creating ${accountType} account`;
      },

      setHeader() {
        let accountType = "";
        switch (this.type) {
          case "Syndicate":
            accountType = "structured";
            break;

          case "Unstructured Syndicate":
            accountType = "unstructured";
            break;
          case "Community":
            accountType = "community";
            break;

          default:
            break;
        }
        this.headerText = `Create ${accountType} account`;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
