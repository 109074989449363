<template>
  <section
    class="md:tw-w-full tw-w-11/12 tw-flex tw-flex-col tw-items-center verification__section"
  >
    <BackBtn class="tw-mr-auto tw-mb-7" />
    <h1 class="tw-font-bold tw-mb-3 tw-text-2xl tw-text-secondary">
      Enter your confirmation code
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-text-center verification__text">
      We sent an email to
      <span class="tw-font-bold tw-text-base tw-text-black">{{
        this.email
      }}</span
      >. <br />
      Please type it in so we can verify your account
    </p>
    <CodeVerificationForm
      @verify="verifyAccount"
      class="tw-w-full"
      @resend-code="resendToken"
    />
  </section>
</template>

<script>
  import { mapActions } from "vuex";
  import CodeVerificationForm from "@/components/onboarding/forms/CodeVerificationForm";
  import api from "@/services/auth";
  import responseHandler from "@/utils/responseHandler.js";
  import { getItem, clearStorage } from "@/utils/storage.js";
  import BackBtn from "@/components/general/BackBtn";
  export default {
    name: "CodeVerification",

    components: {
      CodeVerificationForm,
      BackBtn,
    },
    data() {
      return {
        email: "",
        showWelcomeModal: false,
      };
    },

    mounted() {
      this.email = getItem("org_details").email;
      this.showToast({
        description: "Please verify your account",
        display: true,
        type: "info",
      });
    },

    methods: {
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      ...mapActions("loadingModule", ["verifySuccess", "verifyLoading"]),
      ...mapActions(["showToast"]),

      async verifyAccount(payload) {
        this.verifyLoading(true);
        try {
          const response = await api.verifyOrganisation({ token: payload });
          const { data, message, status } = response.data;
          if (status === "success") {
            this.verifySuccess(true);
            this.showToast({
              description: message,
              display: true,
              type: "success",
            });
            this.fetchOrgInfo(data._id);
          }
        } catch (error) {
          responseHandler.handleError(error);
          this.verifyLoading(false);
          clearStorage();
        }
      },

      async fetchOrgInfo(id) {
        try {
          const response = await this.getOrganisationProfile(id);
          if (response) {
            this.$router.push({ name: "Welcome" });
          } else {
            clearStorage();
            this.$router.push({ name: "SignIn" });
          }
          this.verifyLoading(false);
        } catch (error) {
          responseHandler.handleError(error);
          this.verifyLoading(false);
          clearStorage();
        }
      },

      async resendToken() {
        try {
          const response = await api.sendToken({ email: this.email });
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
        } catch (error) {
          responseHandler.handleError(error);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .verification {
    &__section {
      max-width: 420px;
      margin-top: 50px;
      @media (min-width: 768px) {
        margin-top: 57px;
      }
    }
    &__text {
      max-width: 377px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
</style>
