<template>
	<Accordion :info="info" @is-active="setActiveTab" :verified="isVerified">
		<form @submit.prevent="checkFormValidation">
			<div
				class="lg:tw-flex tw-flex-wrap tw-gap-x-5 tw-mb-10 lg:tw-justify-between"
				v-for="(doc, index) in businessDocs"
				:key="index"
			>
				<InputField
					label="Document name"
					:validate="validateForm"
					:max-length="70"
					class="tw-mb-6 lg:tw-max-w-[513px] tw-w-full"
					type="text"
					name="name"
					:currentValue="doc.name"
					@input-changed="storeBusinessDocInfo($event, index)"
					@is-valid="handleValidation"
				/>
				<template v-if="doc.name">
					<label
						:for="doc.name"
						class="tw-text-[#323E67] tw-leading-[-150%] tw-tracking-[-0.12px] tw-text-sm tw-mb-2 tw-block tw-w-full"
						>Upload {{ doc.name }}</label
					>
					<div class="lg:tw-max-w-[513px] tw-w-full">
						<DragNDrop
							class="tw-mb-4"
							accept=".pdf"
							:validate="validateForm"
							:required="true"
							:currentValue="doc.url"
							:startUpload="uploadIdDoc"
							@re-upload="uploadIdDoc = false"
							@upload-complete="setUpdatedUrl($event, index)"
							@file-added="setDoc($event, index)"
							@is-valid="handleValidation"
							@upload-error="idUploadError"
						>
							<p
								class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2"
							>
								Drag and drop file
							</p>
						</DragNDrop>
					</div>
					<div
						class="lg:tw-max-w-[157px] tw-text-xs tw-text-[#8791A7] tw-mb-10 lg:tw-text-right"
					>
						<p class="tw-mb-6 lg:tw-text-right">
							Your document must be clear and legible.
						</p>
						<p class=" lg:tw-text-right">File formats allowed: PDF</p>
					</div>
				</template>
				<button
					type="button"
					v-if="businessDocs.length > 1"
					class="tw-w-36 tw-flex tw-self-center tw-mt-8 hover:tw-bg-transparent tw-text-[tw-text-[#06206B] hover:tw-text-[#06206B]"
					@click="removeDoc(index)"
				>
					<img src="@/assets/img/trash.svg" class="tw-w-5 tw-h-5" />
					<span class="tw-text-sm tw-ml-1">Remove Document</span>
				</button>
			</div>
			<button
				type="button"
				@click="addDoc"
				class="tw-text-[#06206B] tw-text-sm tw-leading-[150%] tw-h-6 tw-rounded tw-block tw-ml-auto tw-flex tw-items-center tw-gap-x-1 hover:tw-bg-transparent hover:tw-text-[#06206B] tw-mt-8"
			>
				<img
					class="tw-h-6 tw-w-6"
					src="https://res.cloudinary.com/djalafcj9/image/upload/v1654006155/getequityV2/Frame_ebk0fw.svg"
				/>
				Add Document
			</button>
			<div class="lg:tw-max-w-[513px] tw-mr-auto tw-w-full md:tw-mt-10">
				<button
					class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white tw-block tw-py-4 tw-max-w-[158px] tw-ml-auto tw-text-sm"
					:disabled="iDLoading"
				>
					<span v-if="!iDLoading">Submit</span>
					<img
						v-show="iDLoading"
						class="tw-m-auto"
						src="@/assets/img/spinner.svg"
						alt="animated spinner"
					/>
				</button>
			</div>
		</form>
	</Accordion>
</template>

<script>
import DragNDrop from "@/components/general/DragNDrop";
import InputField from "@/components/general/InputField";
import Accordion from "@/components/setup/Accordion";
import { mapActions, mapGetters } from "vuex";
export default {
	name: "BusinessDocument",
	data() {
		return {
			businessDocs: [
				{
					name: "",
					url: "",
					type: "Other",
				},
			],
			info: {
				title: "Business Documents (Optional)",
				description:
					"This may include syndicate registration documents, policy document, service agreements etc",
			},
			validateForm: false,
			validationResult: [],
			uploaded: [],
			iDLoading: false,
			uploadIdDoc: false,
		};
	},
	components: {
		Accordion,
		DragNDrop,
		InputField,
	},
	inject: ["activeTab"],
	computed: {
		...mapGetters("organisationModule", ["organisationDetails"]),
		isVerified() {
			return this.docs.length > 0;
		},
		docs() {
			return this.organisationDetails?.documents?.filter(
				(doc) => doc.type === "Other"
			);
		},
	},
	watch: {
		// validationResult: {
		//   deep: true,
		//   handler(val) {
		//     if (val.length === 2 && !val.includes(false)) {
		//       this.uploadIdDocument();
		//     }
		//   },
		// },
		docs(val) {
			if (val && val.length > 0) {
				const array = [];
				val.map((doc) => {
					const object = {
						name: doc.name,
						url: doc.url,
						type: "Other",
						_id: doc._id,
					};
					array.push(object);
				});
				this.businessDocs = array;
			}
		},
	},
	methods: {
		...mapActions("organisationModule", [
			"submitKycDocument",
			"updateKycDocument",
		]),
		...mapActions(["showToast"]),
		setActiveTab(title) {
			this.$emit("is-active", title);
		},
		checkFormValidation() {
			this.validationResult = [];
			this.validateForm = true;
			setTimeout(() => {
				this.validateForm = false;
			}, 1000);
		},
		handleValidation(value) {
			this.validationResult.push(value);
			//we divide by 2 because we validate name, and url for each document which results in one extra Boolean for each document added
			if (this.validationResult.length / 2 === this.businessDocs.length) {
				if (!this.validationResult.includes(false)) {
					this.iDLoading = true;
					this.uploadIdDoc = true;
				}
			}
		},
		storeBusinessDocInfo(val, index) {
			this.businessDocs[index][val.name] = val.value;
		},
		setDoc(url, index) {
			this.businessDocs[index].url = url;
		},
		addDoc() {
			this.businessDocs.push({
				name: "",
				type: "Other",
				url: "",
			});
		},
		removeDoc(index) {
			this.businessDocs.splice(index, 1);
		},

		setUpdatedUrl(url, index) {
			this.businessDocs[index].url = url;
			this.uploaded.push(true);
			if (this.uploaded.length === this.businessDocs.length) {
				this.submitBusinessDocument();
			}
		},

		async submitBusinessDocument() {
			try {
				const action =
					this.docs && this.docs.length >= 1
						? this.updateKycDocument({
								documents: this.businessDocs,
								type: "Business",
						  })
						: this.submitKycDocument({
								documents: this.businessDocs,
								type: "Business",
						  });
				const response = await action;
				if (response) {
					const { message } = response.data;
					this.showToast({
						description: message,
						display: true,
						type: "success",
					});
				}
				this.$emit("is-active", "Legal Documentation");
				this.iDLoading = false;
				this.uploadIdDoc = false;
				this.uploaded = [];
			} catch (error) {
				return error;
			}
		},

		idUploadError() {
			this.iDLoading = false;
			this.uploadIdDoc = false;
		},
	},
};
</script>

<style lang="scss" scoped></style>
