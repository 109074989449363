<template>
  <div
    id="reset-link"
    class="md:tw-w-96 tw-w-11/12 tw-flex tw-flex-col tw-items-center tw-pb-8"
  >
    <h1 class="tw-font-bold tw-mb-3 tw-text-2xl tw-text-secondary">
      Reset your password
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-text-center tw-mb-12">
      Don’t worry, happens to the best of us
    </p>
    <RequestLinkForm @submitData="submitData" />
    <div class="tw-flex tw-mt-6">
      <p class="tw-text-sm tw-text-gray">Remembered password?</p>
      &nbsp;
      <router-link to="/onboarding/signin" class="tw-text-sm tw-text-primary">
        Sign In
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import RequestLinkForm from "@/components/onboarding/forms/RequestLinkForm";

  export default {
    name: "RequestLink",

    components: { RequestLinkForm },

    methods: {
      ...mapActions("organisationModule", ["resetPasswordLink"]),
      ...mapActions("loadingModule", [
        "requestLinkSuccess",
        "requestLinkLoading",
      ]),
      ...mapActions(["showToast"]),

      async submitData(payload) {
        this.requestLinkLoading(true);
        const response = await this.resetPasswordLink(payload);
        if (response) {
          this.requestLinkSuccess(true);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.$router.push({ name: "SignIn" });
        }
        this.requestLinkLoading(false);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
