<template>
  <form @submit.prevent="signIn" class=" tw-w-full">
    <div class="tw-grid tw-grid-rows-2 tw-gap-y-6">
      <InputField
        type="email"
        name="email"
        @input-changed="storeValue"
        @is-valid="handleValidation"
        label="Enter email address"
        border="tw-border-[#DDE1E9]"
        :validate="validateForm"
      />
      <!-- N.B: label value is used for validation. Update validation if label value changes -->
      <OnboardingPasswordInput
        class="tw-mt-3"
        placeHolder="Enter your password"
        label="Password"
        :password="payload.password"
        :error="passwordError || {}"
        name="password"
        :disabled="loading"
        @set="setPassword"
        border="tw-border-[#DDE1E9]"
        @validate="validateMethod"
      />
    </div>
    <OnboardingBtn :loading="loading" title="Sign in" />
  </form>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import OnboardingPasswordInput from "@/components/onboarding/OnboardingPasswordInput";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn";
  import InputField from "@/components/general/InputField";

  export default {
    name: "SigninForm",

    components: {
      OnboardingPasswordInput,
      OnboardingBtn,
      InputField,
    },

    emits: ["signIn"],

    data() {
      return {
        v$: useValidate(),
        payload: { email: "", password: "" },
        passwordError: {},
        emailError: {},
        validateForm: false,
        validationResult: [],
      };
    },

    validations() {
      return {
        payload: {
          email: { required },
          password: { required },
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.loadingModule.signinLoading,
        success: (state) => state.loadingModule.signinSuccess,
      }),
    },

    methods: {
      ...mapActions("loadingModule", ["signinSuccess"]),
      storeValue({ name, value }) {
        this.payload[name] = value;
      },
      handleValidation(val) {
        this.validationResult.push(val);
      },
      signIn() {
        this.v$.$validate();
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          this.$emit("signIn", this.payload);
        }
      },

      setPassword(password) {
        this.payload.password = password;
      },

      validateMethod(field) {
        switch (field) {
          case "Password":
            this.v$.$reset();
            this.v$.payload.password.$touch();
            if (this.v$.$errors.length === 0) return (this.passwordError = {});
            this.passwordError = this.v$.$errors[0];
            break;

          default:
            break;
        }
      },
    },

    watch: {
      success(newValue) {
        if (newValue === true) {
          this.payload = { email: "", password: "" };
          this.signinSuccess(false);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
