<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isVerified"
    :status="organisationDetails.kyc.legalDocument"
  >
    <form @submit.prevent="uploadIdDocument">
      <div
        class="lg:tw-flex tw-flex-wrap tw-gap-x-5 tw-mb-10"
        v-for="(doc, index) in legalDocument.documents"
        :key="index"
      >
        <label
          for="Pitch Deck"
          class="tw-text-[#323E67] tw-leading-[-150%] tw-tracking-[-0.12px] tw-text-sm tw-mb-2 tw-block tw-w-full"
          >Upload {{ doc.name }}
          <span class="tw-text-red-error" v-if="doc.required">*</span></label
        >
        <div class="lg:tw-max-w-[513px] tw-w-full md:tw-w-2/3">
          <DragNDrop
            class="tw-mb-4"
            accept="image/png,image/jpeg,image/jpg,.pdf"
            :validate="validateForm"
            :currentValue="doc.url"
            :startUpload="uploadIdDoc"
            @re-upload="uploadIdDoc = false"
            @file-added="setDoc($event, index)"
            @is-valid="handleValidation"
            @upload-complete="setUpdatedUrl($event, index)"
            @upload-error="idUploadError"
            :required="doc.required"
          >
            <!-- <p
              class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2"
            >
              Drag and drop file
            </p> -->
          </DragNDrop>
        </div>
        <div class="lg:tw-max-w-[157px] tw-text-xs tw-text-[#8791A7] tw-mb-10">
          <p class="tw-mb-6">Your document must be clear and legible.</p>
          <p>File formats allowed: PDF, JPEG, PNG</p>
        </div>
      </div>
      <div class="lg:tw-max-w-[513px] tw-mr-auto tw-w-full">
        <button
          class="tw-w-full tw-rounded tw-text-sm tw-bg-primary tw-text-white tw-block tw-py-4 tw-max-w-[158px] tw-ml-auto tw-text-sm"
          :disabled="iDLoading"
        >
          <span v-if="!iDLoading">Submit</span>
          <img
            v-show="iDLoading"
            class="tw-m-auto"
            src="@/assets/img/spinner.svg"
            alt="animated spinner"
          />
        </button>
      </div>
    </form>
  </Accordion>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import Accordion from "@/components/setup/Accordion";
  import DragNDrop from "@/components/general/DragNDrop";
  export default {
    name: "LegalDocument",
    data() {
      return {
        legalDocument: {
          documents: [
            {
              name: "Incorporation Document",
              url: "",
              type: "legal",
              required: true,
            },
            // {
            //   name: "CAC 2 or Shares Allotment Document",
            //   url: "",
            //   type: "legal",
            //   required: false,
            // },
            // {
            //   name: "Memorandum of Association",
            //   url: "",
            //   type: "legal",
            //   required: false,
            // },
          ],
        },
        info: {
          title: "Legal Documentation",
          description: "Upload documents that show legitimacy.",
        },
        validated: [],
        uploaded: [],
        validateForm: false,
        iDLoading: false,
        uploadIdDoc: false,
      };
    },
    components: {
      Accordion,
      DragNDrop,
    },
    inject: ["activeTab"],
    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
      isVerified() {
        return this.organisationDetails.kyc.legalDocument === "Approved";
      },
      legalDocuments() {
        const legalDocument = this.legalDocument.documents.filter((doc) => {
          return doc.url;
        });
        return legalDocument;
      },
    },
    watch: {
      validated: {
        deep: true,
        handler(val) {
          if (
            val.length === this.legalDocument.documents.length &&
            !val.includes(false)
          ) {
            this.iDLoading = true;
            this.uploadIdDoc = true;
            this.setLoading(true);
          }
        },
      },
    },
    methods: {
      ...mapActions("organisationModule", ["submitKycDocument"]),
      ...mapActions(["showToast", "setLoading"]),
      setActiveTab(title) {
        this.$emit("is-active", title);
      },
      uploadIdDocument() {
        this.validated = [];
        this.validateForm = true;
        setTimeout(() => {
          this.validateForm = false;
        }, 1000);
      },
      handleValidation(value) {
        this.validated.push(value);
      },
      setDoc(url, index) {
        this.legalDocument.documents[index].url = url;
      },

      setUpdatedUrl(url, index) {
        this.legalDocument.documents[index].url = url;
        this.uploaded.push(true);
        if (this.uploaded.length === this.legalDocument.documents.length) {
          this.submitLegalDocument();
        }
      },

      async submitLegalDocument() {
        this.legalDocument.documents.forEach((doc) => {
          delete doc.required;
        });
        const legalDocument = this.legalDocument.documents.filter((doc) => {
          return doc.url;
        });
        try {
          const response = await this.submitKycDocument({
            documents: legalDocument,
          });
          if (response) {
            const { message } = response.data;
            this.showToast({
              description: message,
              display: true,
              type: "success",
            });
            if (this.organisationDetails.kyc.bank) {
              this.$router.push({ name: "DashboardView" });
            } else {
              this.$emit("is-active", "Bank Account (Optional)");
            }
          }
        } catch (error) {
          return error;
        }
        this.iDLoading = false;
        this.uploadIdDoc = false;
      },

      idUploadError() {
        this.iDLoading = false;
        this.uploadIdDoc = false;
        this.setLoading(false);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
