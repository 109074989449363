<template>
  <form @submit.prevent="submitData" class="tw-w-full">
    <!-- N.B: placeholder value is used for validation. Update validation if placeholder value changes -->
    <InputField
      class="tw-mb-6"
      type="text"
      name="name"
      :max-length="70"
      @input-changed="storeValue"
      @is-valid="handleValidation"
      border="tw-border-[#DDE1E9]"
      label="Choose a name for your portal"
      :validate="validateForm"
    />
    <InputField
      type="email"
      name="email"
      @input-changed="storeValue"
      @is-valid="handleValidation"
      label="Enter email address"
      border="tw-border-[#DDE1E9]"
      :validate="validateForm"
    />
    <p class="tw-mt-2 tw-text-xs tw-text-gray tw-mb-6">
      A 5-Digit code will be sent to your email
    </p>
    <div class="xs:tw-flex tw-block tw-mb-6">
      <OnboardingCountryCodeInput
        border="tw-border-[#DDE1E9]"
        label="Country code"
        @set="setCountryCode"
      />
      <!-- N.B: placeholder value is used for validation. Update validation if placeholder value changes -->
      <OnboardingPhoneNumberInput
        placeHolder="Phone number"
        label="Phone number"
        border="tw-border-[#DDE1E9]"
        :phoneNumber="telephoneNumber"
        :error="telephoneNumberError || {}"
        @set="setTelephoneNumber"
        @validate="validateMethod"
      />
    </div>
    <!-- N.B: label value is used for validation. Update validation if label value changes -->
    <OnboardingPasswordInput
      border="tw-border-[#DDE1E9]"
      class="tw-mb-6"
      label="Password"
      name="password"
      :password="payload.password"
      :error="passwordError || {}"
      inputName="password"
      :disabled="loading"
      @set="setPassword"
      @validate="validateMethod"
    />
    <div class="tw-grid tw-grid-cols-2 tw-mt-3 tw-px-2">
      <div class="tw-flex">
        <img
          v-if="passwordLength"
          src="@/assets/img/green-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <img
          v-if="!passwordLength"
          src="@/assets/img/gray-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <p class="tw-text-gray tw-text-xs">
          At least 8 characters
        </p>
      </div>
      <div class="tw-flex">
        <img
          v-if="containsNumber"
          src="@/assets/img/green-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <img
          v-if="!containsNumber"
          src="@/assets/img/gray-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <p class="tw-text-gray tw-text-xs">
          At least one number
        </p>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-mt-2 tw-px-2">
      <div class="tw-flex">
        <img
          v-if="containsUppercase"
          src="@/assets/img/green-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <img
          v-if="!containsUppercase"
          src="@/assets/img/gray-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <p class="tw-text-gray tw-text-xs">
          At least one uppercase letter
        </p>
      </div>

      <div class="tw-flex">
        <img
          v-if="containsSpecial"
          src="@/assets/img/green-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <img
          v-if="!containsSpecial"
          src="@/assets/img/gray-shield.svg"
          class="tw-mr-1"
          alt="green shield icon"
        />
        <p class="tw-text-gray tw-text-xs">
          At least one special character
        </p>
      </div>
    </div>
    <!-- N.B: placeholder value is used for validation. Update validation if placeholder value changes -->
    <OnboardingPasswordInput
      class="tw-mt-6"
      label="Confirm Password"
      border="tw-border-[#DDE1E9]"
      name="confirmPassword"
      :password="payload.confirmPassword"
      :error="confirmPasswordError || {}"
      inputName="confirmPassword"
      :disabled="enableConfirmPassword"
      @set="setConfirmPassword"
      @validate="validateMethod"
    />
    <OnboardingBtn :loading="loading" title="Continue" />
  </form>
</template>

<script>
  import InputField from "@/components/general/InputField";
import OnboardingBtn from "@/components/onboarding/OnboardingBtn";
import OnboardingCountryCodeInput from "@/components/onboarding/OnboardingCountryCodeInput";
import OnboardingPasswordInput from "@/components/onboarding/OnboardingPasswordInput";
import OnboardingPhoneNumberInput from "@/components/onboarding/OnboardingPhoneNumberInput";
import useValidate from "@vuelidate/core";
import {
helpers,
maxLength,
minLength,
numeric,
required,
sameAs,
} from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";

  export default {
    name: "DataCollectionForm",

    components: {
      InputField,
      OnboardingBtn,
      OnboardingPhoneNumberInput,
      OnboardingCountryCodeInput,
      OnboardingPasswordInput,
    },

    emits: ["submitData"],

    data() {
      return {
        v$: useValidate(),
        payload: {
          name: "",
          // industry: "",
          email: "",
          type: "Syndicate",
          phone: "",
          password: "",
          confirmPassword: "",
        },
        validateForm: false,
        validationResult: [],
        enableConfirmPassword: true,
        countryCode: "",
        telephoneNumber: "",
        // industryError: {},
        telephoneNumberError: {},
        passwordError: {},
        live: true,
        confirmPasswordError: {},
        containsNumber: false,
        containsSpecial: false,
        containsUppercase: false,
        passwordLength: false,
      };
    },

    validations() {
      return {
        payload: {
          password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(50),
            containsUppercase: helpers.withMessage(
              "Must contain upper case letter",
              function(value) {
                return /[A-Z]/.test(value);
              }
            ),
            containsNumber: helpers.withMessage("Must contain number", function(
              value
            ) {
              return /[0-9]/.test(value);
            }),
            containsSpecial: helpers.withMessage(
              "Must contain special character [#?!@$%^&*-]",
              function(value) {
                return /[#?!@$%^&*-]/.test(value);
              }
            ),
          },
          confirmPassword: {
            required,
            sameAs: sameAs(this.payload.password),
          },
        },
        telephoneNumber: {
          required,
          numeric,
          maxLength: maxLength(12),
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.loadingModule.createLoading,
        success: (state) => state.loadingModule.createSuccess,
      }),
    },

    methods: {
      ...mapActions("loadingModule", ["createSuccess"]),
      storeValue({ name, value }) {
        this.payload[name] = value;
      },
      handleValidation(val) {
        this.validationResult.push(val);
      },
      submitData() {
        this.v$.$validate();
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          delete this.payload.confirmPassword;
          this.payload.live = true;
          this.$emit("submitData", this.payload);
        }
      },

      setSyndicateName(details) {
        this.payload.name = details.name;
      },

      // setIndustryName(details) {
      //   this.payload.industry = details.name;
      // },

      setCountryCode(code) {
        this.countryCode = code;
        this.concatPhoneNumber();
      },

      setTelephoneNumber(telephoneNumber) {
        this.telephoneNumber = telephoneNumber;
        this.concatPhoneNumber();
      },

      setPassword(password) {
        this.payload.password = password;
        if (password.length >= 8) {
          this.enableConfirmPassword = false;
        } else {
          this.enableConfirmPassword = true;
        }
      },
      setConfirmPassword(password) {
        this.payload.confirmPassword = password;
      },

      validateMethod(field) {
        switch (field) {
          case "Phone number":
            this.v$.$reset();
            this.v$.telephoneNumber.$touch();
            if (this.v$.$errors.length === 0)
              return (this.telephoneNumberError = {});
            this.telephoneNumberError = this.v$.$errors[0];
            break;

          case "Password":
            this.v$.$reset();
            this.v$.payload.password.$touch();
            if (this.v$.$errors.length === 0) return (this.passwordError = {});
            this.passwordError = this.v$.$errors[0];
            break;

          case "Confirm Password":
            this.v$.$reset();
            this.v$.payload.confirmPassword.$touch();
            if (this.v$.$errors.length === 0) {
              return (this.confirmPasswordError = {});
            }
            this.confirmPasswordError = this.v$.$errors[0];
            break;

          default:
            break;
        }
      },

      concatPhoneNumber() {
        this.payload.phone = this.countryCode.concat(this.telephoneNumber);
      },

      checkPassword(password) {
        this.containsNumber = /[0-9]/.test(password);
        this.containsSpecial = /[#?!@$%^&*-]/.test(password);
        this.containsUppercase = /[A-Z]/.test(password);
        this.passwordLength = password.length >= 8;
      },
    },

    watch: {
      passwordError(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.checkPassword(this.payload.password);
        }
      },

      success(newValue) {
        if (newValue === true) {
          this.payload = {
            name: "",
            // industryName: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
          };
          this.telephoneNumber = "";
          this.createSuccess(false);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
