<template>
  <div
    class="toggle"
    :class="{ 'toggle--active': toggled }"
    @click.self="toggled = !toggled"
  >
    <button
      type="button"
      class="toggle__button"
      :class="{ 'toggle__button--active': toggled }"
      @click="toggled = !toggled"
    ></button>
  </div>
</template>

<script>
  export default {
    name: "ToggleButton",
    data() {
      return {
        toggled: false,
      };
    },
    props: {
      value: {
        type: Boolean,
        required: false,
      },
      reset: {
        type: Boolean,
        required: false,
      },
    },
    mounted() {
      this.toggled = this.value;
    },
    emits: ["value-selected"],
    watch: {
      toggled(val) {
        this.$emit("value-selected", val);
      },
      reset(val) {
        if (val) {
          this.toggled = this.value;
        }
      },
      value(bool) {
        this.toggled = bool;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .toggle {
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    height: 25px;
    background: #d0d5e0;
    padding-left: 2px;
    padding-right: 2px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    &--active {
      background: #4d84ff;
    }
    &__button {
      border-radius: 50%;
      border: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      padding: 0;
      margin-bottom: 1px;
      transform: translateY(0.5px);
      transition: transform 300ms ease-in-out;
      &--active {
        transform: translateX(15px);
      }
    }
  }
</style>
