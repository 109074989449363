<template>
  <InputFieldLabel
    :label="label"
    :inputActive="inputActive"
    @label-click="focusInput"
  >
    <input
      :type="inputType"
      class="tw-w-full tw-rounded custom-form__input tw-pl-6 tw-text-secondary tw-h-12"
      :class="{
        'tw-border-error': showError === true,
        [border]: border,
      }"
      :name="label"
      :ref="name"
      :id="name"
      @focus="inputActive = true"
      :value="password"
      @input="updatePassword($event.target.value)"
      @blur="handleBlur"
      :disabled="disabled"
      required
    />
    <button type="button" @click="showPassword" :disabled="!password">
      <img
        :src="
          inputType === 'password'
            ? 'https://res.cloudinary.com/djalafcj9/image/upload/v1656583771/getequityV2/Group_szivpg.png'
            : 'https://res.cloudinary.com/djalafcj9/image/upload/v1654615404/getequityV2/Frame_f1btgz.svg'
        "
        class="tw-absolute tw-right-4 tw-bottom-[16px]"
        alt="show password icon"
      />
    </button>
    <p class="tw-absolute tw--top-4 tw-right-px tw-text-error tw-text-xs">
      {{ errorMsg }}
    </p>
  </InputFieldLabel>
</template>

<script>
import InputFieldLabel from "@/components/general/InputFieldLabel";
export default {
  name: "OnboardingPasswordInput",
  components: {
    InputFieldLabel,
  },

  emits: ["set", "validate"],

  props: {
    label: {
      type: String,
      default: () => "",
      required: true,
    },
    password: {
      type: String,
      default: () => "",
      required: true,
    },
    error: {
      type: Object,
      default: () => {},
      required: true,
    },
    name: {
      type: String,
      default: () => "",
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    //border color class for the input
    border: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      inputType: "password",
      showError: false,
      errorMsg: "",
      inputActive: false,
    };
  },

  methods: {
    checkError() {
      switch (this.error.$property) {
        case "password":
          this.showError = true;
          this.errorMsg = this.error.$message;
          break;
        case "confirmPassword":
          this.showError = true;
          this.errorMsg = this.error.$message;
          break;

        default:
          this.showError = false;
          this.errorMsg = "";
          break;
      }
    },
    focusInput() {
      this.$refs[this.name].focus();
    },
    handleBlur() {
      this.$emit("validate", this.label);
      if (!this.password) {
        this.inputActive = false;
      }
    },

    showPassword() {
      if (this.inputType === "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    updatePassword(value) {
      this.$emit("set", value);
    },
  },

  watch: {
    error(newValue, oldValue) {
      deep: true;
      immediate: true;
      if (newValue !== oldValue) {
        this.checkError();
      }
    },
    password(val) {
      if (val) {
        this.inputActive = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-form {
  &__input {
    transition: all 500ms ease-in-out;
    padding-left: 28px;
    background-color: #fff;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &--default {
      border: 1px solid #e1e6f0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &--error {
      border: 1px solid #db4343;
    }
    &:focus {
      border: 1px solid #4d84ff;
    }
  }
}
</style>
