<template>
  <div class="tw-relative tw-w-80 tw-flex tw-flex-col tw-w-full">
    <label :for="label"></label>
    <input
      type="tel"
      class="tw-text-sm tw-rounded tw-p-4 xs:tw-rounded-tl-none xs:tw-rounded-l-none
    focus:tw-border-primary tw-bg-white"
      :class="{
        'tw-border-error': showError === true,
        [border]: border,
      }"
      :value="phoneNumber"
      @input="updatePhoneNumber"
      @blur="$emit('validate', placeHolder)"
      @keypress="isNumber($event)"
      :placeholder="placeHolder"
      required
    />
    <p class="tw-absolute tw--top-4 tw-right-px tw-text-error tw-text-xs">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
import { isNumber } from "@/utils/helpers.js";

export default {
  name: "OnboardingPhoneNumberInput",

  emits: ["set", "validate"],

  props: {
    placeHolder: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "",
      required: true,
    },
    phoneNumber: {
      type: String,
      default: "",
      required: true,
    },
    error: {
      type: Object,
      default: {},
      required: true,
    },
    //border color class for the input
    border: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      showError: false,
      errorMsg: "",
    };
  },

  methods: {
    isNumber,

    checkError() {
      if (Object.keys(this.error).length === 0) {
        this.showError = false;
        this.errorMsg = "";
        return;
      }
      if (this.error.$property === "telephoneNumber") {
        this.showError = true;
        this.errorMsg = this.error.$message;
      }
    },

    updatePhoneNumber(event) {
      this.$emit("set", event.target.value);
    },
  },

  watch: {
    error(newValue, oldValue) {
      deep: true;
      immediate: true;
      if (newValue !== oldValue) {
        this.checkError();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
