import router from "@/router";

export default {
  methods: {
    navigateTo(page) {
      if (page.split("/")[2] === "undefined") {
        return;
      }
      router.push(page);
    },
  },
};
