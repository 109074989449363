<template>
  <main
    class="tw-text-[#1A2139] tw-bg-[#F7F9FD] tw-min-h-screen tw-overflow-auto setup__main"
  >
    <MobileSetupSideBar />
    <SetupSidebar />
    <!-- <Loader v-if="loading" /> -->
    <Header />
    <div
      class="setup__div tw-w-full tw-px-8 tw-py-8 tw-pt-32 tw-min-h-screen lg:tw-px-0"
    >
      <div
        class="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-mb-10"
      >
        <div class="tw-max-w-[300px]">
          <h1 class="tw-text-[#1A2139] tw-font-bold tw-text-lg tw-mb-1">
            Finish Setting Up Your Account
          </h1>
          <p class="tw-text-[#4C5B90] tw-text-[14px] tw-leading-[150%]">
            No pressure. You can always come back to this.
          </p>
        </div>
        <router-link
          :to="{ name: 'DashboardView' }"
          class="tw-text-primary tw-leading-[150%] tw-mt-3 md:tw-mt-0"
          >Skip for Now ></router-link
        >
      </div>
      <Accordion
        v-if="organisationDetails && organisationDetails.kyc"
        :info="info"
        @is-active="setActiveTab"
        :verified="organisationDetails.kyc?.email"
        :status="organisationDetails.kyc?.email === true ? 'Approved' : ''"
      >
      </Accordion>
      <CompanyInfo @is-active="setActiveTab" />
      <Configuration @is-active="setActiveTab" />
      <IdentityDocument @is-active="setActiveTab" />
      <BusinessDocument @is-active="setActiveTab" />
      <LegalDocument @is-active="setActiveTab" />
      <BankInfo @is-active="setActiveTab" />
      <router-link :to="{ name: 'DashboardView' }">
        <button
          class="tw-bg-primary tw-rounded tw-text-white tw-h-12 tw-text-sm tw-w-[155px] tw-mt-16 tw-block tw-ml-auto"
        >
          Done
        </button>
      </router-link>
    </div>
  </main>
</template>

<script>
  import SetupSidebar from "@/layout/navigation/SetupSidebar";
  import MobileSetupSideBar from "@/layout/navigation/MobileSetupSideBar";
  import Accordion from "@/components/setup/Accordion";
  import Loader from "@/components/notification/Loader";
  import Header from "@/layout/navigation/Header";
  import { mapActions, mapGetters } from "vuex";
  import CompanyInfo from "@/components/setup/CompanyInfo";
  import IdentityDocument from "@/components/setup/IdentityDocument";
  import LegalDocument from "@/components/setup/LegalDocument";
  import Configuration from "@/components/setup/Configuration";
  import BusinessDocument from "@/components/setup/BusinessDocument";
  import BankInfo from "@/components/setup/BankInfo";
  //import to make provide reactive
  import { computed } from "vue";
  export default {
    name: "Setup",
    data() {
      return {
        info: {
          title: "Email Verification",
          description: "Assure us that this email address belongs to you.",
        },
        activeTab: "",
      };
    },
    components: {
      Accordion,
      Header,
      Loader,
      CompanyInfo,
      IdentityDocument,
      SetupSidebar,
      MobileSetupSideBar,
      BankInfo,
      LegalDocument,
      BusinessDocument,
      Configuration,
    },
    provide() {
      return {
        // explicitly provide a computed property
        activeTab: computed(() => this.activeTab),
      };
    },
    computed: {
      ...mapGetters(["loading"]),
      ...mapGetters("organisationModule", ["organisationDetails"]),
    },
    created() {
      this.getOrganisationProfile();
    },
    methods: {
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      setActiveTab(title) {
        this.activeTab = title;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .setup {
    &__main {
      @include fadeIn;
      height: 100%;
      display: flex;
    }
    &__div {
      width: 100%;
      max-width: 870px;
      margin: 0 auto;

      @media screen and (min-width: 1280px) {
        width: calc(100% - #{$side-bar-width});
      }
      @media (min-width: 1500px) {
        /* max-width: 1400px; */
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
</style>
