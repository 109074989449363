<template>
	<Accordion :info="info" @is-active="setActiveTab" :verified="isFilled">
		<form
			@submit.prevent="checkFormValidation"
			class="personal-info__form tw-max-w-[690px] tw-mx-auto"
		>
			<div class="tw-mb-6 md:tw-flex tw-gap-4">
				<div
					class="tw-w-full md:tw-w-1/2 personal-info__div tw-mb-6 md:tw-mb-0 md:tw-max-w-[336px]"
				>
					<label
						for="avatar"
						class="tw-text-sm tw-text-[#323E67] tw-tracking-[-0.12px] tw-mb-1"
						>Syndicate Name</label
					>
					<InputField
						label=""
						:validate="validateForm"
						class=""
						:max-length="70"
						type="text"
						name="name"
						:currentValue="organisationDetails.name"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
				</div>

				<div
					class="tw-w-full md:tw-w-1/2 personal-info__div md:tw-max-w-[336px]"
				>
					<label
						for="avatar"
						class="tw-text-sm tw-text-[#323E67] tw-tracking-[-0.12px] tw-mb-1"
						>Date of Incorporation</label
					>
					<Datepicker
						class="tw-h-12 tw-rounded"
						:textInput="true"
						:upperLimit="new Date()"
						:autoApply="true"
						dateOfIncorporation
						:enableTimePicker="false"
						placeholder="Date of Incorporation"
						v-model="orgInfo.dateOfIncorporation"
						:class="[isError]"
					></Datepicker>
					<p
						class="tw-text-red-error tw-w-full"
						v-if="!orgInfo.dateOfIncorporation && isValidated"
					>
						Value is Required
					</p>
				</div>
			</div>
			<div class="tw-mb-6 md:tw-flex tw-gap-4">
				<InputField
					label="Company Phone Number"
					:validate="validateForm"
					class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
					type="tel"
					name="phone"
					:currentValue="organisationDetails.phone"
					@input-changed="storeValue"
					@is-valid="handleValidation"
				/>
				<!-- <Dropdown
          class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
          name="stage"
          label="Select Stage"
          :validate="validateForm"
          :currentValue="organisationDetails.stage"
          :options="stages"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        /> -->
			</div>
			<!-- <div class="tw-mb-6 md:tw-flex tw-gap-4">
        <Dropdown
          class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
          name="industry"
          label="Select Industry"
          :validate="validateForm"
          :currentValue="organisationDetails.industry"
          :options="industries"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
      </div> -->
			<p
				class="tw-text-[#323E67] tw-leading-[150%] tw-text-sm tw-tracking-[-0.12px] tw-font-normal tw-mb-2"
			>
				Location
			</p>
			<div class="tw-mb-6 md:tw-flex tw-gap-4">
				<TextField
					label="Address"
					:validate="validateForm"
					height="tw-h-[109px]"
					class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
					name="officeAddress"
					:currentValue="organisationDetails.officeAddress"
					@input-changed="storeValue"
					@is-valid="handleValidation"
				/>
				<div class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]">
					<Dropdown
						class="tw-mb-3"
						name="country"
						label="Select Country"
						:validate="validateForm"
						:currentValue="country"
						:options="countryList"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
					<Dropdown
						class=""
						name="state"
						label="Select State"
						:validate="validateForm"
						:currentValue="organisationDetails.state"
						:options="stateList"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
				</div>
			</div>
			<div class="tw-mb-6 md:tw-flex tw-gap-4">
				<div class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]">
					<label
						for="avatar"
						class="tw-text-sm tw-text-[#323E67] tw-tracking-[-0.12px] tw-mb-2"
						>Syndicate Company Logo
						<span class="tw-text-red-error">*</span></label
					>
					<DragNDrop
						:validate="isValidated"
						accept="image/png, image/jpeg, image/jpg"
						:currentValue="orgInfo.image"
						:startUpload="uploadImage"
						@re-upload="uploadImage = false"
						@upload-complete="uploadComplete"
						@file-added="setImage"
						@upload-error="imageUploadError"
					/>
				</div>
				<div
					class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 tw-w-full md:tw-max-w-[336px]"
				>
					<label
						for="avatar"
						class="tw-text-sm tw-text-[#323E67] tw-tracking-[-0.12px] tw-mb-2"
						>Profile Summary <span class="tw-text-red-error">*</span></label
					>
					<TextField
						label=""
						:validate="validateForm"
						height="tw-h-[180px]"
						name="about"
						:currentValue="organisationDetails.about"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
				</div>
			</div>
			<div>
				<label for="founder" class="tw-mb-3 tw-block">Founder</label>
				<div
					class="tw-mb-8"
					v-for="(founder, index) in orgInfo.founders"
					:key="index"
				>
					<div class="tw-mb-6 md:tw-flex tw-gap-4">
						<InputField
							label="Founder's first name"
							:validate="validateForm"
							class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
							type="text"
							name="fname"
							:max-length="70"
							:currentValue="founder.fname"
							@input-changed="storeFounderInfo($event, index)"
							@is-valid="handleFounderValidation"
						/>
						<InputField
							label="Founder's last name"
							:validate="validateForm"
							class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
							type="text"
							name="lname"
							:max-length="70"
							:currentValue="founder.lname"
							@input-changed="storeFounderInfo($event, index)"
							@is-valid="handleFounderValidation"
						/>
					</div>
					<div class="tw-mb-6 md:tw-flex tw-gap-4">
						<InputField
							label="Founder's email"
							:validate="validateForm"
							class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
							type="email"
							name="email"
							:currentValue="founder.email"
							@input-changed="storeFounderInfo($event, index)"
							@is-valid="handleFounderValidation"
						/>
						<InputField
							label="Role"
							:validate="validateForm"
							class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
							type="text"
							name="role"
							:max-length="70"
							:currentValue="founder.role"
							@input-changed="storeFounderInfo($event, index)"
							@is-valid="handleFounderValidation"
						/>
					</div>
					<button
						type="button"
						v-if="orgInfo.founders.length > 1"
						class="tw-w-36 tw-flex tw-self-center tw-mt-8 hover:tw-bg-transparent tw-text-[tw-text-[#06206B] hover:tw-text-[#06206B]"
						@click="removeFounder(index)"
					>
						<img src="@/assets/img/trash.svg" class="tw-w-5 tw-h-5" />
						<span class="tw-text-sm tw-ml-1">Remove founder</span>
					</button>
				</div>
				<button
					type="button"
					@click="addFounder"
					class="tw-text-[#06206B] tw-text-sm tw-leading-[150%] tw-h-6 tw-rounded tw-block tw-ml-auto tw-flex tw-items-center tw-gap-x-1 hover:tw-bg-transparent hover:tw-text-[#06206B] tw-mb-8"
				>
					<img
						class="tw-h-6 tw-w-6"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1654006155/getequityV2/Frame_ebk0fw.svg"
					/>
					Add Founder
				</button>
			</div>
			<div class="tw-mb-6">
				<label for="socials" class="tw-mb-3 tw-block tw-w-full">Links</label>
				<div class="tw-mb-6 md:tw-flex tw-gap-4">
					<LinkInput
						linkPrefix="https://"
						label="Company website"
						:validate="validateForm"
						class="tw-mb-6 tw-w-full md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
						name="website"
						:currentValue="organisationDetails.website"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
					<LinkInput
						linkPrefix="https://facebook.com/"
						label="Facebook"
						:validate="validateForm"
						class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
						:required="false"
						name="facebook"
						:currentValue="organisationDetails.facebook"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
				</div>
				<div class="tw-mb-6 md:tw-flex tw-flex-wrap tw-gap-4">
					<LinkInput
						linkPrefix="https://twitter.com/"
						label="Twitter handle"
						:validate="validateForm"
						class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
						:required="false"
						name="twitter"
						:currentValue="organisationDetails.twitter"
						@input-changed="storeValue"
						@is-valid="handleValidation"
					/>
				</div>
			</div>
			<div class="tw-max-w-[200px] tw-ml-auto">
				<CustomButton title="Update" :disabled="loading" :loading="loading" />
			</div>
		</form>
	</Accordion>
</template>

<script>
import Accordion from "@/components/setup/Accordion";
import InputField from "@/components/general/InputField";
import { mapActions, mapGetters } from "vuex";
import TextField from "@/components/general/TextField";
import Dropdown from "@/components/general/Dropdown";
import countryRegionData from "@/utils/js/countryRegionData.js";
import countryCode from "@/utils/json/countryCode.json";
import CustomButton from "@/components/general/BtnComponent";
import LinkInput from "@/components/general/LinkInput";
import DragNDrop from "../general/DragNDrop";
import { stages } from "@/utils/helpers";
import { industries } from "@/utils/helpers";
export default {
	name: "CompanyInfo",
	data() {
		return {
			info: {
				title: "Syndicate Information",
				description:
					"Complete company information and details about your founders.",
			},
			validateForm: false,
			validationResult: [],
			founderValidation: [],
			country: "",
			stages,
			industries,
			orgInfo: {
				name: "",
				dateOfIncorporation: null,
				phone: "",
				// stage: "",
				officeAddress: null,
				country: "",
				state: "",
				image: "",
				about: "",
				founders: [
					{
						fname: "",
						lname: "",
						email: "",
						role: "",
					},
				],
				website: null,
				facebook: null,
				twitter: null,
				// industry: "",
			},
			uploadImage: false,
			countryRegionData,
			countryCode,
			isValidated: false,
			stateList: [],
		};
	},
	components: {
		Accordion,
		InputField,
		DragNDrop,
		TextField,
		Dropdown,
		countryRegionData,
		CustomButton,
		LinkInput,
	},
	inject: ["activeTab"],
	watch: {
		"orgInfo.country"(val) {
			//if country and orgInfo.country are not the same
			if (!this.country.includes(val)) {
				//filter list of countries by code
				const filteredCountry = this.countryCode.countries.filter(
					(country) => country.code === val
				);
				//if result is not empty, concatenate value and assign to country
				if (filteredCountry.length > 0) {
					const countryConcat = `${filteredCountry[0].name} ${filteredCountry[0].flag} - ${filteredCountry[0].code}`;
					this.country = countryConcat;
				}
			}
		},
		country(val) {
			const value = val?.split(" ");
			if (value) {
				this.orgInfo.country = value[value.length - 1];
				this.getStates(val);
			}
		},
		organisationDetails(val) {
			if (val) {
				this.fillForm();
			}
		},
		validationResult: {
			deep: true,
			handler(val) {
				if (
					val.length === Object.keys(this.orgInfo).length &&
					!val.includes(false)
				) {
					this.uploadImage = true;
					this.setLoading(true);
				} else {
					this.founderValidation = [];
					this.isValidated = false;
				}
			},
		},
	},
	computed: {
		...mapGetters(["loading"]),
		...mapGetters("organisationModule", ["organisationDetails"]),
		isActiveTab() {
			return this.activeTab === this.info.title;
		},
		isError() {
			if (!this.orgInfo.dateOfIncorporation && this.isValidated) {
				return "date__input--error date__input";
			}
			return "date__input";
		},
		countryList() {
			const bannedCountries = [
				"Balkans",
				"Belarus",
				"Burma (Myanmar)",
				"Central African Republic",
				"Cuba",
				"Democratic Republic of the Congo",
				"Iran",
				"Iraq",
				"Lebanon",
				"Libya",
				"Nicaragua",
				"North Korea",
				"Russia",
				"Somalia",
				"Sudan and Darfur",
				"Syria",
				"Venezuela",
				"Yemen",
				"Zimbabwe",
			];
			const countryList = this.countryCode.countries
				.filter((country) => !bannedCountries.includes(country.name))
				.map((country) => {
					return `${country.name} ${country.flag} - ${country.code}`;
				});
			return countryList;
		},
		isFilled() {
			return !Object.values(this.orgInfo).includes(null);
		},
	},
	mounted() {
		if (this.organisationDetails) {
			this.fillForm();
		}
	},
	emits: ["is-active"],
	methods: {
		...mapActions(["showToast", "setLoading"]),
		...mapActions("organisationModule", ["updateOrgDetails"]),
		setActiveTab(title) {
			this.$emit("is-active", title);
		},
		fillForm() {
			this.orgInfo.dateOfIncorporation =
				this.organisationDetails.dateOfIncorporation;
			this.orgInfo.country = this.organisationDetails.country;
			this.orgInfo.image = this.organisationDetails.image
				? this.organisationDetails.image
				: "";
			if (this.organisationDetails.founders.length > 0) {
				this.orgInfo.founders = this.organisationDetails.founders;
			}
		},
		storeValue(object) {
			if (object.name !== "country") {
				this.orgInfo[object.name] = object.value;
			} else {
				this[object.name] = object.value;
			}
		},

		getStates(country) {
			this.countryRegionData.map((countryData) => {
				if (
					country.includes(countryData.countryName) ||
					country.includes(countryData.countryShortCode)
				) {
					this.stateList = countryData.regions.map((state) => {
						return state.name;
					});
				}
			});
		},

		storeFounderInfo(val, index) {
			this.orgInfo.founders[index][val.name] = val.value;
		},

		setImage(url) {
			this.orgInfo.image = url;
		},
		uploadComplete(url) {
			this.orgInfo.image = url;
			this.updateCompanyInfo();
			this.uploadImage = false;
		},

		imageUploadError() {
			this.uploadImage = false;
			this.setLoading(false);
		},
		cleanupFounders() {
			const founders = this.orgInfo.founders;
			founders.map((document) => {
				delete document.organisation;
				delete document.createdAt;
				delete document.updatedAt;
				delete document.__v;
			});
			this.orgInfo.founders = founders;
		},

		async updateCompanyInfo() {
			this.cleanupFounders();
			const data = this.orgInfo;
			if (!data.facebook) {
				delete data.facebook;
			}
			if (!data.twitter) {
				delete data.twitter;
			}
			try {
				const response = await this.updateOrgDetails(data);
				const { message } = response.data;
				this.showToast({
					description: message,
					display: true,
					type: "success",
				});
				this.uploadImage = false;
				this.$emit("is-active", "Configuration Settings");
				this.setLoading(false);
			} catch (error) {
				this.setLoading(false);
				return error;
			}
		},
		handleValidation(value) {
			this.validationResult.push(value);
		},
		handleFounderValidation(value) {
			this.founderValidation.push(value);
			//we divide by 4 because we validate fname, lname, email and role for each founder which results in three extra Boolean for each founder added
			if (this.founderValidation.length / 4 === this.orgInfo.founders.length) {
				if (this.founderValidation.includes(false)) {
					this.handleValidation(false);
				} else {
					this.handleValidation(true);
				}
			}
		},
		checkFormValidation() {
			this.validationResult = [];
			this.validateForm = true;
			this.isValidated = true;
			if (this.orgInfo.dateOfIncorporation) {
				this.handleValidation(true);
			} else {
				this.handleValidation(false);
			}
			if (this.orgInfo.image) {
				this.handleValidation(true);
			} else {
				this.handleValidation(false);
			}
			setTimeout(() => {
				this.validateForm = false;
			}, 1000);
		},
		addFounder() {
			this.orgInfo.founders.push({
				fname: "",
				lname: "",
				email: "",
				role: "",
			});
		},
		removeFounder(index) {
			this.orgInfo.founders.splice(index, 1);
		},
	},
};
</script>

<style lang="scss">
.date {
	&__input {
		height: 48px;
		border-radius: 4px;
		transition: all 0.3s ease;
		border: 1px solid #e1e6f0 !important;
		flex-grow: 1;
		&--error {
			border: 1px solid #db4343 !important;
		}
	}
}
.dp__main {
	@apply md:tw-w-full;
}
.dp__input {
	border: 0;
	height: 46px;
}
</style>
