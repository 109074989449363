import axios from "axios"
import responseHandler from "@/utils/responseHandler.js"
import api from "@/utils/api"

const endpoints = {
    /*
     * Send bank connection response to the server
     */
    async finalizeBankConnection(type, data = {}) {
        try {
            const response = await axios.post(
                `${process.env.VUE_APP_UTILITY_API}/bank/connect/${type}`,
                data, {
                    headers: api.getHeader(),
                }
            )
            return response
        } catch (e) {
            responseHandler.handleError(e)
            return e
        }
    },

    /*
     * Send bank connection initiation to the server
     */
    async initiateBankConnection(type, data = {}) {
        try {
            const response = await axios.post(
                `${process.env.VUE_APP_UTILITY_API}/bank/initiate/${type}`,
                data, {
                    headers: api.getHeader(),
                }
            )
            return response
        } catch (e) {
            responseHandler.handleError(e)
            return e
        }
    },

    /**
     * Send bank disconnection response to the server
     * @param {string} bankId
     */
    async diconnectBank(bankId) {
        try {
            const response = await axios.delete(`/bank/${bankId}`, {
                headers: api.getHeader(),
            })
            return response
        } catch (e) {
            responseHandler.handleError(e)
            return e
        }
    },
}
export default endpoints