<template>
  <form @submit.prevent="submitData" class=" tw-w-full">
    <div class="tw-mt-4 tw-gap-y-6">
      <!-- N.B: label value is used for validation. Update validation if label value changes -->
      <OnboardingPasswordInput
        label="Password"
        :password="payload.password"
        border="tw-border-[#DDE1E9]"
        :error="passwordError || {}"
        name="password"
        :disabled="false"
        @set="setPassword"
        @validate="validateMethod"
      />
      <div class="tw-grid tw-grid-cols-2 tw-mt-1 tw-px-2">
        <div class="tw-flex">
          <img
            v-if="passwordLength"
            src="@/assets/img/green-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <img
            v-if="!passwordLength"
            src="@/assets/img/gray-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <p class="tw-text-gray tw-text-xs">
            At least 8 characters
          </p>
        </div>
        <div class="tw-flex">
          <img
            v-if="containsNumber"
            src="@/assets/img/green-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <img
            v-if="!containsNumber"
            src="@/assets/img/gray-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <p class="tw-text-gray tw-text-xs">
            At least one number
          </p>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-2 tw-mt-2 tw-px-2">
        <div class="tw-flex">
          <img
            v-if="containsUppercase"
            src="@/assets/img/green-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <img
            v-if="!containsUppercase"
            src="@/assets/img/gray-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <p class="tw-text-gray tw-text-xs">
            At least one uppercase letter
          </p>
        </div>

        <div class="tw-flex">
          <img
            v-if="containsSpecial"
            src="@/assets/img/green-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <img
            v-if="!containsSpecial"
            src="@/assets/img/gray-shield.svg"
            class="tw-mr-1"
            alt="green shield icon"
          />
          <p class="tw-text-gray tw-text-xs">
            At least one special character
          </p>
        </div>
      </div>
      <OnboardingPasswordInput
        class="tw-mt-4"
        border="tw-border-[#DDE1E9]"
        label="Confirm password"
        :password="payload.confirmPassword"
        :error="confirmPasswordError || {}"
        name="confirmPassword"
        :disabled="enableConfirmPassword"
        @set="setConfirmPassword"
        @validate="validateMethod"
      />
    </div>
    <!-- N.B: placeholder value is used for validation. Update validation if placeholder value changes -->
    <OnboardingBtn :loading="loading" title="Change Password" />
  </form>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import {
    required,
    minLength,
    sameAs,
    helpers,
    maxLength,
  } from "@vuelidate/validators";
  import OnboardingPasswordInput from "@/components/onboarding/OnboardingPasswordInput";
  import OnboardingBtn from "@/components/onboarding/OnboardingBtn";

  export default {
    name: "ResetPasswordForm",

    components: {
      OnboardingPasswordInput,
      OnboardingBtn,
    },

    emits: ["submitData"],

    data() {
      return {
        v$: useValidate(),
        payload: {
          password: "",
          confirmPassword: "",
        },
        enableConfirmPassword: true,
        passwordError: {},
        confirmPasswordError: {},
        containsNumber: false,
        containsSpecial: false,
        containsUppercase: false,
        passwordLength: false,
      };
    },

    validations() {
      return {
        payload: {
          password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(50),
            containsUppercase: helpers.withMessage(
              "Must contain upper case letter",
              function(value) {
                return /[A-Z]/.test(value);
              }
            ),
            containsNumber: helpers.withMessage("Must contain number", function(
              value
            ) {
              return /[0-9]/.test(value);
            }),
            containsSpecial: helpers.withMessage(
              "Must contain special character [#?!@$%^&*-]",
              function(value) {
                return /[#?!@$%^&*-]/.test(value);
              }
            ),
          },
          confirmPassword: {
            required,
            sameAs: sameAs(this.payload.password),
          },
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.loadingModule.resetPasswordLoading,
        success: (state) => state.loadingModule.resetPasswordSuccess,
      }),
    },

    methods: {
      ...mapActions("loadingModule", ["resetPasswordSuccess"]),

      submitData() {
        this.v$.$validate();
        const hasValue = Object.keys(this.payload).every(
          (key) => this.payload[key]
        );
        if (this.v$.$errors.length === 0 && hasValue) {
          delete this.payload.confirmPassword;
          this.$emit("submitData", this.payload);
        }
      },

      setPassword(password) {
        this.payload.password = password;
        if (password.length >= 8) {
          this.enableConfirmPassword = false;
        } else {
          this.enableConfirmPassword = true;
        }
      },

      setConfirmPassword(password) {
        this.payload.confirmPassword = password;
      },

      validateMethod(field) {
        switch (field) {
          case "Password":
            this.v$.$reset();
            this.v$.payload.password.$touch();
            if (this.v$.$errors.length === 0) return (this.passwordError = {});
            this.passwordError = this.v$.$errors[0];
            break;

          case "Confirm password":
            this.v$.$reset();
            this.v$.payload.confirmPassword.$touch();
            if (this.v$.$errors.length === 0)
              return (this.confirmPasswordError = {});
            this.confirmPasswordError = this.v$.$errors[0];
            break;

          default:
            break;
        }
      },

      checkPassword(password) {
        this.containsNumber = /[0-9]/.test(password);
        this.containsSpecial = /[#?!@$%^&*-]/.test(password);
        this.containsUppercase = /[A-Z]/.test(password);
        this.passwordLength = password.length >= 8;
      },
    },

    watch: {
      passwordError(newValue, oldValue) {
        deep: true;
        immediate: true;
        if (newValue !== oldValue) {
          this.checkPassword(this.payload.password);
        }
      },

      success(newValue) {
        deep: true;
        immediate: true;
        if (newValue === true) {
          this.payload = {
            password: "",
            confirmPassword: "",
          };
          this.resetPasswordSuccess(false);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
