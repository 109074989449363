<template>
  <section
    class="md:tw-w-full tw-w-11/12 tw-flex tw-flex-col tw-items-center pin__section tw-py-8 md:tw-py-20 lg:tw-py-24"
  >
    <h1 class="tw-font-bold tw-mb-3 tw-text-2xl tw-text-secondary">
      Set your 4-digit PIN
    </h1>
    <p class="tw-text-sm tw-text-black-light tw-text-center tw-mb-10 pin__text">
      You’ll use this PIN for signing in and confirming your transactions
    </p>
    <CreatePinForm class="tw-w-full" />
  </section>
</template>

<script>
  import CreatePinForm from "@/components/onboarding/forms/CreatePinForm";
  import { getItem } from "@/utils/storage.js";

  export default {
    name: "CreatePin",

    components: {
      CreatePinForm,
    },
    created() {
      if (!this.organisationDetails) {
        this.$router.push({ name: "SignIn" });
      }
    },
    computed: {
      organisationDetails() {
        const org = getItem("org_details");
        return org;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pin {
    &__section {
      max-width: 610px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-top: 24px;
    }
    &__text {
      max-width: 365px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
